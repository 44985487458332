import Vue from 'vue'
import Meta from 'vue-meta'
import { createRouter } from './router.js'
import NoSsr from './components/no-ssr.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_nuxtdevicedetectplugin6d86dfaf_3d34bb24 from 'nuxt_plugin_nuxtdevicedetectplugin6d86dfaf_3d34bb24' // Source: ./nuxt-device-detect.plugin.6d86dfaf.js (mode: 'all')
import nuxt_plugin_axios_0069638c from 'nuxt_plugin_axios_0069638c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_sentryclient_0e8d5157 from 'nuxt_plugin_sentryclient_0e8d5157' // Source: ./sentry-client.js (mode: 'client')
import nuxt_plugin_googleanalytics_14827ca8 from 'nuxt_plugin_googleanalytics_14827ca8' // Source: ./google-analytics.js (mode: 'client')
import nuxt_plugin_vueawesomeswiper_4af85e3f from 'nuxt_plugin_vueawesomeswiper_4af85e3f' // Source: ../plugins/vue-awesome-swiper (mode: 'client')
import nuxt_plugin_vscrollto_6c55c7ae from 'nuxt_plugin_vscrollto_6c55c7ae' // Source: ../plugins/v-scroll-to (mode: 'client')
import nuxt_plugin_vodal_331f2700 from 'nuxt_plugin_vodal_331f2700' // Source: ../plugins/vodal (mode: 'all')
import nuxt_plugin_vuetextmask_63b66f62 from 'nuxt_plugin_vuetextmask_63b66f62' // Source: ../plugins/vue-text-mask (mode: 'all')
import nuxt_plugin_vuemultiselect_f0c5f110 from 'nuxt_plugin_vuemultiselect_f0c5f110' // Source: ../plugins/vue-multiselect (mode: 'all')
import nuxt_plugin_vuetabscomponent_156e82ea from 'nuxt_plugin_vuetabscomponent_156e82ea' // Source: ../plugins/vue-tabs-component (mode: 'all')
import nuxt_plugin_vueclipboard2_3c353cf9 from 'nuxt_plugin_vueclipboard2_3c353cf9' // Source: ../plugins/vue-clipboard2 (mode: 'all')
import nuxt_plugin_vuetoasted_f6148aee from 'nuxt_plugin_vuetoasted_f6148aee' // Source: ../plugins/vue-toasted (mode: 'client')
import nuxt_plugin_vuemultipleprogress_3386af0d from 'nuxt_plugin_vuemultipleprogress_3386af0d' // Source: ../plugins/vue-multiple-progress (mode: 'client')
import nuxt_plugin_vuebemcn_466f9aaa from 'nuxt_plugin_vuebemcn_466f9aaa' // Source: ../plugins/vue-bem-cn (mode: 'all')
import nuxt_plugin_vuegooglemaps_4aa051ae from 'nuxt_plugin_vuegooglemaps_4aa051ae' // Source: ../plugins/vue-google-maps (mode: 'all')
import nuxt_plugin_vuetippy_73799e27 from 'nuxt_plugin_vuetippy_73799e27' // Source: ../plugins/vue-tippy (mode: 'client')
import nuxt_plugin_utils_1ea7651c from 'nuxt_plugin_utils_1ea7651c' // Source: ../plugins/utils.js (mode: 'all')
import nuxt_plugin_vuetify_d6afc2c2 from 'nuxt_plugin_vuetify_d6afc2c2' // Source: ../plugins/vuetify.js (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_jquery_e2c6283a from 'nuxt_plugin_jquery_e2c6283a' // Source: ../plugins/jquery.js (mode: 'client')
import nuxt_plugin_jqueryplugins_10209030 from 'nuxt_plugin_jqueryplugins_10209030' // Source: ../plugins/jquery-plugins.js (mode: 'client')
import nuxt_plugin_fontawesome_28010cbc from 'nuxt_plugin_fontawesome_28010cbc' // Source: ../plugins/font-awesome.js (mode: 'all')

// Component: <NoSsr>
Vue.component(NoSsr.name, NoSsr)

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>`
Vue.component(Nuxt.name, Nuxt)

// vue-meta configuration
Vue.use(Meta, {
  keyName: 'head', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-n-head', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-n-head-ssr', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'hid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    router,
    store,
    nuxt: {
      defaultTransition,
      transitions: [ defaultTransition ],
      setTransitions(transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [ transitions ]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },
      err: null,
      dateErr: null,
      error(err) {
        err = err || null
        app.context._errored = !!err
        err = err ? normalizeError(err) : null
        const nuxt = this.nuxt || this.$options.nuxt
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) ssrContext.nuxt.error = err
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    store,
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) throw new Error('inject(key, value) has no key provided')
    if (typeof value === 'undefined') throw new Error('inject(key, value) has no value provided')
    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) return
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Vue.prototype.hasOwnProperty(key)) {
        Object.defineProperty(Vue.prototype, key, {
          get() {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Plugin execution

  if (typeof nuxt_plugin_nuxtdevicedetectplugin6d86dfaf_3d34bb24 === 'function') {
    await nuxt_plugin_nuxtdevicedetectplugin6d86dfaf_3d34bb24(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_0069638c === 'function') {
    await nuxt_plugin_axios_0069638c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_0e8d5157 === 'function') {
    await nuxt_plugin_sentryclient_0e8d5157(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_14827ca8 === 'function') {
    await nuxt_plugin_googleanalytics_14827ca8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueawesomeswiper_4af85e3f === 'function') {
    await nuxt_plugin_vueawesomeswiper_4af85e3f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vscrollto_6c55c7ae === 'function') {
    await nuxt_plugin_vscrollto_6c55c7ae(app.context, inject)
  }

  if (typeof nuxt_plugin_vodal_331f2700 === 'function') {
    await nuxt_plugin_vodal_331f2700(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetextmask_63b66f62 === 'function') {
    await nuxt_plugin_vuetextmask_63b66f62(app.context, inject)
  }

  if (typeof nuxt_plugin_vuemultiselect_f0c5f110 === 'function') {
    await nuxt_plugin_vuemultiselect_f0c5f110(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetabscomponent_156e82ea === 'function') {
    await nuxt_plugin_vuetabscomponent_156e82ea(app.context, inject)
  }

  if (typeof nuxt_plugin_vueclipboard2_3c353cf9 === 'function') {
    await nuxt_plugin_vueclipboard2_3c353cf9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetoasted_f6148aee === 'function') {
    await nuxt_plugin_vuetoasted_f6148aee(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuemultipleprogress_3386af0d === 'function') {
    await nuxt_plugin_vuemultipleprogress_3386af0d(app.context, inject)
  }

  if (typeof nuxt_plugin_vuebemcn_466f9aaa === 'function') {
    await nuxt_plugin_vuebemcn_466f9aaa(app.context, inject)
  }

  if (typeof nuxt_plugin_vuegooglemaps_4aa051ae === 'function') {
    await nuxt_plugin_vuegooglemaps_4aa051ae(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetippy_73799e27 === 'function') {
    await nuxt_plugin_vuetippy_73799e27(app.context, inject)
  }

  if (typeof nuxt_plugin_utils_1ea7651c === 'function') {
    await nuxt_plugin_utils_1ea7651c(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetify_d6afc2c2 === 'function') {
    await nuxt_plugin_vuetify_d6afc2c2(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_jquery_e2c6283a === 'function') {
    await nuxt_plugin_jquery_e2c6283a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_jqueryplugins_10209030 === 'function') {
    await nuxt_plugin_jqueryplugins_10209030(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_28010cbc === 'function') {
    await nuxt_plugin_fontawesome_28010cbc(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    app,
    store,
    router
  }
}

export { createApp, NuxtError }
