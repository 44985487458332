<script>
  export default {
    name: 'BottomMenuComponent',
    data () {
      return {
        links: [
          {
            title: '% Распродажа',
            icon: 'brand',
            url: '/kits/bolshaya-rasprodazha/'
          },
          {
            title: 'Заказать товар по кодам',
            icon: 'code',
            url: '/code/'
          },
          {
            title: 'Заказать из Excel',
            icon: 'excel',
            url: '/excel/'
          },
          {
            title: 'Заказать из PDF-каталога',
            icon: 'mouse',
            url: '/pdf/'
          }
        ]
      }
    }
  }
</script>

<template lang="html">
    <div class="c-bottom-menu v-component">
        <div class="row" :class="bem('container')">
            <ul class="col-12 menu">
                <li class="item" v-for="link in links">
                    <nuxt-link class="link" :to="link.url">
                        <i class="i-png icon" :class="link.icon"></i>
                        {{ link.title }}
                    </nuxt-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~assets/scss/mixins';
    // $debug: true;
    @include component('bottom-menu') {
        @include element('container') {
            .menu {
                @include media-breakpoint-down('lg') {
                    text-align: center;
                    vertical-align: bottom;
                    // margin-top: 1px;
                }

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                list-style: none;
                margin-bottom: 0;
            }
            .item {
                @include media-breakpoint-up('xl') {
                    float: left;
                    margin-right: 4%;
                }
                @include media-breakpoint-down('lg') {
                    @include hover-focus-active {
                        box-shadow: inset 0 5px 25px 1px $gray-lighter;
                    }
                    background: rgba($white, 0.7);
                    border-bottom: 1px solid darken($gray-lighter, 5%);
                    border-left: 1px solid darken($gray-lighter, 5%);
                    border-top: 1px solid darken($gray-lighter, 5%);
                    box-sizing: border-box;
                    float: left;
                    margin-right: 0;
                    position: relative;
                    width: 25%;
                    &:last-child {
                        border-right: 1px solid darken($gray-lighter, 5%);
                    }
                }
                @include media-breakpoint-down('xs') {
                    width: 50%;
                    &:nth-child(2n+2) {
                        border-right: 1px solid darken($gray-lighter, 5%);
                    }
                    &:nth-child(3),
                    &:nth-child(4) {
                        border-top-style: none;
                    }
                }
                align-items: center;
                display: flex;
                justify-content: center;
                text-align: center;

                &:last-child {
                    margin-right: 0;
                }
            }
            .link {
                @include media-breakpoint-up('xl') {
                    line-height: $spacer * 2.6;
                }
                @include media-breakpoint-down('lg') {
                    display: block;
                    line-height: initial;
                    padding: $spacer 10%;
                    width: 100%;
                }
                @include hfas('active') {
                    color: $brand-primary;
                    text-decoration: none;
                }
                color: $gray;
                display: block;
                font-size: rem(12px);
                text-transform: uppercase;
                width: 100%;
            }
            .icon {
                @include media-breakpoint-down('lg') {
                    display: inline-block;
                    margin-bottom: $spacer / 2;
                    width: 100% !important;
                }

                margin-right: $spacer / 2;
            }
        }
        &.v-component {
        }
    }
</style>
