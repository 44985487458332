const getDefaultState = () => ({
  filter: {
    blocks: [],
    price: {
      min: 0,
      minHTML: 0,
      max: 0,
      maxHTML: 0
    }
  },
  products: [],
  section: {
    current: {
      title: 'Каталог',
      code: '',
      id: '0',
      depthLevel: 0
    },
    parent: {
      title: 'Каталог',
      code: '',
      id: '0'
    },
    links: []
  },
  sections: [],
  breadcrumbs: [],
  total: 0
})

export const state = () => getDefaultState()

export const getters = {
  catalog (state) {
    return state
  },
  current (state) {
    return state.section.current.code
  },
  filter (state) {
    return state.filter
  },
  seoDescription (state) {
    let description = `<p>В нашем интернет-магазине представлено множество товаров категории <b>${state.section.current.title}</b>, которые вы можете приобрести мелким и крупным оптом по доступным ценам.</p>`;
    description += '<p>Наши цены для оптовых клиентов значительно ниже других магазинов за счет прямых поставок от производителя. Также для оптовых клиентов действует быстрая собственная доставка.</p>';
    return description;
  },
  breadcrumbs (state) {
    const result = [
      {
        active: false,
        title: 'Главная',
        url: '/'
      }
    ]

    if (state.section.current.depthLevel === 0) {
      result.push(
        {
          active: true,
          title: 'Каталог',
          url: '/catalog/'
        }
      )
    } else {
      state.breadcrumbs.forEach(el => result.push(el))
    }

    return result
  }
}

export const actions = {
  updateState (store, { code, query }) {
    return new Promise((resolve, reject) => {
      const params = {
        'SECTION_ID': code,
        ...query,
        'page_num': query.page || 1,
        'page_size': query.quantity || store.rootGetters['user/quantity'],
        'sort': query.sort || store.rootGetters['user/sort']
      };
      this.$axios.$get('/catalog/', { params: params }).then(data => {
        store.commit('UPDATE_STATE', data);
        resolve(data)
      }).catch((response) => {
        reject(response)
      })
    })
  },
  resetState ({ commit }) {
    commit('RESET_STATE')
  }
};

export const mutations = {
  UPDATE_STATE (state, data) {
    state.total = data.total;
    state.section = data.section;
    state.sections = data.sections;
    state.breadcrumbs = data.breadcrumbs;
    state.products = data.products;
    if (data.filter) {
      state.filter.price.min = data.filter.price.min;
      state.filter.price.max = data.filter.price.max;
      state.filter.price.minHTML = data.filter.price.minHTML;
      state.filter.price.maxHTML = data.filter.price.maxHTML;
      state.filter.blocks = data.filter.blocks;
    }
  },
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  }
};
