import * as VueGoogleMaps from '~/node_modules/vue2-google-maps'
import Vue from 'vue'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBZKeYPI-gBpZSkQ8TsaHDds3OtVugUSl8',
    language: 'ru_RU'
    // v: 'OPTIONAL VERSION NUMBER',
    // libraries: 'places', //// If you need to use place input
  }
})
