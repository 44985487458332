export const state = () => ({
  page: []
})

export const mutations = {
  UPDATE_STATE (state, page) {
    state.page = page
  }
}

export const getters = {
  page (state) {
    return state.page
  }
}
