<script>
  export default {
    name: 'CatalogButtonComponent'
  }
</script>

<style lang="scss" scoped>
    @import '~assets/scss/mixins';

    // $debug: true;

    @include component('catalog-button') {
        @include element('button') {
            @include media-breakpoint-down('lg') {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                line-height: rem(32px);
                margin-bottom: 0;
            }
            @include hfas('active') {
                color: darken($gray, 5%);
            }
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top-style: none;
            color: $gray;
            display: block;
            line-height: $spacer * 2.3;
            margin-bottom: -$spacer;
            max-width: 100%;
            padding-left: $spacer * 3;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            white-space: nowrap;
            width: rem(220px);
            z-index: 100;
            .icon {
                left: $spacer * 1.2;
                position: absolute;
                top: $spacer;
            }
        }
        &.v-component {
        }
    }
</style>

<template lang="html">
    <div class="c-catalog-button v-component">
        <button class="btn btn-warning" :class="bem('button')" @click="$emit('click')">
            Каталог товаров<i class="i-png menu icon"></i>
        </button>
    </div>
</template>
