import sleep from './sleep'

/**
 * @param {Promise<T>} promise
 * @returns {Promise<[T, null] | [null, Error]>}
 */
export const errInline = async (promise) => {
  try {
    return [await promise, null]
  } catch (err) {
    return [null, err]
  }
}

/**
 * @param {() => Promise<T>} promiseCallback
 * @param {number} retryCount
 * @param {number} delay
 * @returns {Promise<[T, null] | [null, Error]>}
 */
export const retryIfError = async (promiseCallback, retryCount = 1, delay = 0) => {
  let result, err

  while (retryCount--) {
    [result, err] = await errInline(promiseCallback())
    if (!err || retryCount === 0) {
      break
    }

    await sleep(delay)
  }

  return [result, err]
}
