export const state = () => ({
  brands: [],
  products: [],
  current: '',
  section: {
    current: {
      title: 'Загрузка'
    },
    parent: {
      title: 'Загрузка'
    },
    links: []
  },
  total: 0
});

export const getters = {
  brands (state) {
    return state.brands
  },
  products (state) {
    return state.products
  },
  current (state) {
    return state.current
  },
  section (state) {
    return state.section
  },
  total (state) {
    return state.total
  }
};

export const actions = {
  updateState ({ commit }) {
    this.$axios.$get('/catalog/brands').then(data => {
      commit('UPDATE_STATE', data)
    })
  }
};

export const mutations = {
  UPDATE_STATE (state, data) {
    state.brands = data
  },
  GET_BRAND_PRODUCTS (state, data) {
    state.products = data.products;
    state.section = data.section;
    state.current = data.brand;
    state.total = data.total;
  }
};
