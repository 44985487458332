export const state = () => ({
  product: {
    code: 0,
    name: 'Загрузка',
    images: [
      {
        big: '//cdn.poryadok.ru/images/sct/no_photo.png',
        medium: '//cdn.poryadok.ru/images/sct/no_photo.png',
        small: '//cdn.poryadok.ru/images/sct/no_photo.png'
      }
    ],
    quantity: 0,
    delta: 0,
    price: 0,
    oldPrice: 0,
    description: '',
    packs: [
      { quantity: 0 },
      { quantity: 0 }
    ]
  },
  section: {
    current: {
      title: 'Загрузка'
    },
    parent: {
      title: 'Загрузка'
    },
    links: []
  },
  recommend: false,
  products: [],
  breadcrumbs: []
})

export const getters = {
  product (state) {
    return state.product
  },
  section (state) {
    return state.section
  },
  current (state) {
    return state.product.code
  },
  products (state) {
    return state.products
  },
  recommend (state) {
    return state.recommend
  },
  breadcrumbs (state) {
    const result = [
      {
        active: false,
        title: 'Главная',
        url: '/'
      }
    ]
    state.breadcrumbs.forEach(el => result.push(el))

    return result
  }
}

export const actions = {
  updateState (store, code) {
    return new Promise((resolve, reject) => {
      const params = {
        ELEMENT_ID: code
      }
      this.$axios.$get('/catalog/', { params: params }).then(data => {
        store.commit('UPDATE_STATE', data)
        resolve(data)
      })
        .catch((response) => {
          this.$axios.$get('/catalog/', { params: { ...params, 'clear_cache': 'Y' } }).then(data => {
            store.commit('UPDATE_STATE', data)
            resolve(data)
          }).catch((response) => {
            reject(response)
          })
        })
    })
  }
}

export const mutations = {
  UPDATE_STATE (state, data) {
    state.product = data.product
    state.section = data.section
    state.recommend = data.recommend
    state.products = data.products
    state.breadcrumbs = data.breadcrumbs
  }
}
