export const state = () => ({
  kit: {
    code: 0,
    title: 'Загрузка',
    image: ''
  },
  sections: [],
  kits: {
    parent: {
      title: 'Акции',
      code: 0
    },
    links: []
  },
  kitsAll: []
})

export const getters = {
  kit (state) {
    return state.kit
  },
  kits (state) {
    return state.kits
  },
  sections (state) {
    return state.sections
  },
  kitsAll (state) {
    return state.kitsAll
  }
}

export const actions = {
  fetchKit (store, code) {
    const params = { code }
    return this.$axios.$get('/kits/kit/', { params })
      .then(data => {
        store.commit('SET_KIT', data)
      })
      .catch((err) => {
        throw err
      })
  },
  fetchList (store) {
    return this.$axios.$get('/kits/')
      .then((data) => {
        store.commit('SET_LIST', data)
      })
      .catch(() => {
        throw new Error('Наборы не найдены')
      })
  }
}

export const mutations = {
  SET_KIT (state, data) {
    const sections = []
    state.kit = {
      code: data.kit.code,
      title: data.kit.title,
      image: data.kit.image
    }
    for (const key in data.sections) {
      sections.push(data.sections[key])
    }
    state.sections = sections
    state.kits.parent.code = data.kits.parent.code
    state.kits.parent.title = data.kits.parent.title
    state.kits.links = data.kits.links
  },
  SET_LIST (state, data) {
    state.kitsAll = data
  }
}
