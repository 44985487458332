export const state = () => ({
  sections: []
})

export const getters = {
  sections (state) {
    return state.sections
  }
}

export const actions = {
  updateState ({ commit }) {
    this.$axios.$get('/career/').then(data => {
      commit('UPDATE_STATE', data)
    })
  }
}

export const mutations = {
  UPDATE_STATE (state, data) {
    state.sections = data
  }
}
