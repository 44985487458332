import Vue from 'vue'

Vue.mixin({
  methods: {
    isObject (value) {
      return (typeof value === 'object') && (value !== null)
    },
    isInn (value) {
      function isInnFormat (value, mapChars) {
        return !/\D/.test(value) && mapChars.map((el) =>
          parseInt(value[el.length]) === el.reduce((s, v, i) =>
            i === 1
              ? s * parseInt(value[i - 1]) + v * parseInt(value[i])
              : s + v * parseInt(value[i])
          ) % 11 % 10
        )
          .filter(el => el === false)
          .length === 0
      }
      return (value.length === 10)
        ? isInnFormat(value, [[2, 4, 10, 3, 5, 9, 4, 6, 8]])
        : (value.length === 12)
          ? isInnFormat(value, [[7, 2, 4, 10, 3, 5, 9, 4, 6, 8], [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]])
          : false
    }
  }
})
