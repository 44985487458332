/**
 * @param {Array<T>} source
 * @param {Array<T>} compareTo
 * @returns {Array<T>}
 */
export function difference (source = [], compareTo = []) {
  return source.reduce((diff, item) => {
    if (compareTo.indexOf(item) < 0) {
      diff.push(item);
    }
    return diff;
  }, []);
}

/**
 * @param {Array<T>} array
 * @param {number} size
 * @returns {Array<Array<T>>}
 */
export function chunkArray (array, size) {
  if (!Array.isArray(array) || !array.length) {
    return [];
  }

  if (!size) {
    return [array];
  }

  const head = array.slice(0, size);
  const tail = array.slice(size);

  return [head, ...chunkArray(tail, size)];
}
