<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'SearchComponent',
    data () {
      return {
        query: '',
        selected: '',
        loading: false,
        disabledOnLoad: true
      }
    },
    computed: {
      ...mapGetters('searchQuick', ['search']),
      _options () {
        let result = []
        if (this.query.length) {
          result.push({
            section: 'Поиск по...',
            items: [{
              code: 'search_query_' + this.query,
              title: this.query,
              url: '/search/' + this.query
            }]
          })
        }
        if (this.search.sections.length > 0) {
          result.push({
            section: 'Разделы',
            items: this.search.sections
          })
        }
        if (this.search.products.length > 0) {
          result.push({
            section: 'Товары',
            items: this.search.products
          })
        }
        return result
      }
    },
    methods: {
      ...mapActions('searchQuick', ['updateState']),
      handleSearch (query) {
        this.loading = true
        if (query.length > 0) {
          this.query = query
        }
        this.updateState(query).then(() => {
          this.loading = false
        }).catch(() => {
          this.$toasted.error(`
            Не удалось выполнить запрос.
            Пожалуйста, перезагрузите страницу и повторите попытку.
          `)
        })
      },
      handleSelect ({ url }) {
        this.$root.$router.push({ path: url })
      },
      customLabel ({ title }) {
        const strip = (html) => {
          let tmp = document.createElement('DIV')
          tmp.innerHTML = html
          return tmp.textContent || tmp.innerText || ''
        }
        return strip(`${title}`)
      }
    },
    mounted () {
      this.disabledOnLoad = false
    }
  }
</script>

<template lang="html">
    <div class="c-search v-component">
        <div class="row" :class="bem('container')">
            <div class="col-12">
                <vue-multiselect class="input" id="ajax"
                                 :value="selected"
                                 :options="_options"
                                 :internal-search="false"
                                 :loading="loading"
                                 track-by="code"
                                 label="title"
                                 :allow-empty="false"
                                 :custom-label="customLabel"
                                 :hide-selected="false"
                                 group-values="items"
                                 group-label="section"
                                 @search-change="handleSearch"
                                 @select="handleSelect"
                                 placeholder="Поиск (название или штрихкод)"
                                 :searchable="true"
                                 selectLabel="перейти"
                                 :close-on-select="true"
                                 :show-labels="false"
                                 :disabled="disabledOnLoad"
                                 :block-keys="['Delete']">
                    <template slot="noResult">
                        <span>Подходящие товары не найдены</span>
                    </template>
                    <template slot="noOptions">
                        <span>Введите поисковой запрос</span>
                    </template>
                </vue-multiselect>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~assets/scss/variables';
    @import '~assets/scss/mixins';
    // $debug: true;
    @include component('search') {
        @include element('container') {
            position: relative;
            .input {
                margin-bottom: $spacer / 2;
            }
            .submit {
                position: absolute;
                right: $spacer * 2;
                top: $spacer / 2;
            }
            .icon {
                display: block;
            }
        }
        @include element('suggest') {
            @include media-breakpoint-only('xl') {
                padding-right: $spacer * 3;
            }
            position: absolute;
            width: 100%;
            z-index: 10000;
            .wrapper {
                background-color: $white;
                border: 1px solid darken($gray-lighter, 10%);
                border-radius: $border-radius;
                padding: $spacer;
            }
            .sections,
            .products {
                list-style: none;
                padding-left: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            .link {
                color: $gray;
                display: block;
                border-bottom: 1px dashed $gray-lighter;
                margin-top: $spacer / 8;
                padding-bottom: $spacer / 8;
            }
            .item {
            }
        }
        &.v-component {
            position: relative;
        }
    }
</style>
