export const state = () => ({
  sections: [],
  products: []
})

export const getters = {
  search (state) {
    return state
  }
}

export const actions = {
  updateState (store, query) {
    return new Promise((resolve, reject) => {
      const params = {
        q: query
      }
      this.$axios.$get('/search/quick/', { params })
        .then(data => {
          store.commit('UPDATE_STATE', { data: data, query: query })
          resolve(data)
        })
        .catch((response) => {
          store.commit('UPDATE_STATE', {
            data: {
              sections: [],
              products: []
            },
            query: query
          })
          reject(response)
        })
    })
  }
}

export const mutations = {
  UPDATE_STATE (state, { data, query }) {
    const unique = (a) => {
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i].title === a[j].title) {
            a.splice(j--, 1)
          }
        }
      }
      return a
    }
    state.sections = unique(data.sections)
    state.products = unique(data.products)
  }
}
