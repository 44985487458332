<script>
  export default {
    name: 'LoadingComponemt',
    data () {
      return {
        show: false,
        canSuccess: true,
        color: '#4979ff',
        failedColor: '#d9534f'
      }
    },
    methods: {
      start () {
        this.show = true
        this.canSuccess = true
        return this
      },
      finish () {
        this.show = false
        return this
      },
      fail () {
        this.canSuccess = false
        return this
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import '~assets/scss/variables';
    @import '~assets/scss/mixins';

    @include component('loading') {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: width 0.2s, opacity 0.4s;
        opacity: 1;
        z-index: 999999;
        background: rgba(255, 255, 255, 0.5);
        text-align: center;
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }

        .lds-grid {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            width: 64px;
            height: 64px;
            transform: scale(2.0);

            div {
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                animation: lds-default 1.2s linear infinite;

                &:nth-child(1) {
                    animation-delay: 0s;
                    top: 29px;
                    left: 53px;
                }

                &:nth-child(2) {
                    animation-delay: -0.1s;
                    top: 18px;
                    left: 50px;
                }

                &:nth-child(3) {
                    animation-delay: -0.2s;
                    top: 9px;
                    left: 41px;
                }

                &:nth-child(4) {
                    animation-delay: -0.3s;
                    top: 6px;
                    left: 29px;
                }

                &:nth-child(5) {
                    animation-delay: -0.4s;
                    top: 9px;
                    left: 18px;
                }

                &:nth-child(6) {
                    animation-delay: -0.5s;
                    top: 18px;
                    left: 9px;
                }

                &:nth-child(7) {
                    animation-delay: -0.6s;
                    top: 29px;
                    left: 6px;
                }

                &:nth-child(8) {
                    animation-delay: -0.7s;
                    top: 41px;
                    left: 9px;
                }

                &:nth-child(9) {
                    animation-delay: -0.8s;
                    top: 50px;
                    left: 18px;
                }

                &:nth-child(10) {
                    animation-delay: -0.9s;
                    top: 53px;
                    left: 29px;
                }

                &:nth-child(11) {
                    animation-delay: -1s;
                    top: 50px;
                    left: 41px;
                }

                &:nth-child(12) {
                    animation-delay: -1.1s;
                    top: 41px;
                    left: 50px;
                }
            }
        }

        @keyframes lds-default {
            0%, 20%, 80%, 100% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.5);
            }
        }
    }
</style>

<template lang="html">
    <div class="c-loading" v-show="show">
        <div class="lds-grid">
            <div v-for="i in 12" :style="{'background-color': canSuccess ? color : failedColor }"></div>
        </div>
    </div>
</template>
