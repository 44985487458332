import Vue from 'vue'
import Toasted from 'vue-toasted'

const options = {
  position: 'bottom-center',
  duration: 3000,
  theme: 'sct'
}

Vue.use(Toasted, options)

var toastedContainer = document.createElement('div')
toastedContainer.setAttribute('id', 'toasted-container')
document.body.appendChild(toastedContainer)
