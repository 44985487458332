import { chunkArray, difference } from '../utils/arrays'
import { retryIfError } from '../utils/promises'

export const state = () => ({
  sections: [],
  products: [],
  selected: [],
  skipped: [],
  quantity: {}
})

export const getters = {
  search (state) {
    return state.products
  },
  selected (state) {
    return state.selected
  },
  quantity (state) {
    return state.quantity
  },
  skipped (state) {
    return state.skipped
  }
}

export const actions = {
  updateState (store, query) {
    return new Promise((resolve, reject) => {
      const params = {
        q: query
      }
      this.$axios.$get('/search/quick/', { params })
        .then(data => {
          store.commit('UPDATE_STATE', data)
          resolve(data)
        })
        .catch((response) => {
          resolve(response)
        })
    })
  },
  addToSelected (store, { code, quantity }) {
    return new Promise((resolve, reject) => {
      const params = {
        ELEMENT_ID: code
      }
      this.$axios.$get('/catalog/', { params: params }).then(data => {
        if (data) {
          const product = data.product
          delete product.images
          delete product.description
          delete product.basketQuantity
          store.commit('ADD_TO_SELECTED', { product, quantity })
          resolve(data)
        } else {
          reject(code)
        }
      })
    })
  },
  async addBatch (store, products) {
    const quantityMap = {}
    const productsCodes = []

    products.forEach(product => {
      quantityMap[product.code] = product.quantity
      productsCodes.push(product.code)
    })

    const chunkSize = 1000

    for (const codes of chunkArray(productsCodes, chunkSize)) {
      const retryCount = 3
      const delay = 200

      const [products, err] = await retryIfError(
        () => this.$axios.$post('/catalog/excel/', { products_codes: codes }),
        retryCount,
        delay
      )
      if (err) {
        codes.forEach(code => store.commit('ADD_TO_SKIPPED', code))
        continue
      }

      products.forEach(product => {
        store.commit('ADD_TO_SELECTED', {
          product,
          quantity: quantityMap[product.code]
        })
      })

      const skippedCodes = difference(codes, products.map(product => Number(product.code)))
      skippedCodes.forEach(code => store.commit('ADD_TO_SKIPPED', code))
    }
  },
  addToSkipped (store, code) {
    store.commit('ADD_TO_SKIPPED', code)
  },
  clearSelected (store) {
    store.commit('CLEAR_SELECTED')
  }
}

export const mutations = {
  UPDATE_STATE (state, data) {
    state.sections = data.sections
    state.products = data.products
  },
  ADD_TO_SELECTED (state, { product, quantity }) {
    if (state.selected.filter(i => i.code === product.code).length === 0) {
      state.selected.push(product)
      state.quantity[product.code] = quantity
    }
  },
  ADD_TO_SKIPPED (state, code) {
    if (!state.skipped.includes(code)) {
      state.skipped.push(code)
    }
  },
  CLEAR_SELECTED (state) {
    state.selected = []
  }
}
