export const state = () => ({
  query: '',
  section: {
    current: {
      title: 'Загрузка'
    },
    parent: {
      title: 'Загрузка'
    },
    links: []
  },
  products: [],
  total: 0
})

export const getters = {
  search (state) {
    return state
  }
}

export const actions = {
}

export const mutations = {
  UPDATE_STATE (state, data) {
    state.total = data.total;
    state.query = data.query;
    state.section = data.section;
    state.products = data.products;
    if (state.products) {
      state.products = data.products
    } else {
      state.products = []
    }
  }
}
