<script>
  import { mapGetters } from 'vuex'
  // import OfferContainer from '~/containers/Index/OfferContainer'
  import HeaderContainer from '~/containers/HeaderContainer'
  import FooterContainer from '~/containers/FooterContainer'

  export default {
    name: 'DefaultLayout',
    components: {
      // OfferContainer,
      HeaderContainer,
      FooterContainer
    },
    computed: {
      ...mapGetters('user', ['token'])
    },
    watch: {
      $route (route) {
        if (route.fullPath && window[`yaCounter${process.env.ymId}`]) {
          window[`yaCounter${process.env.ymId}`].hit(route.fullPath)
        }
        // Render RetailRocket blocks
        setTimeout(() => {
          if (window.retailrocket && window.retailrocket.markup) {
            window.retailrocket.markup.render()
          }
        }, 100)
      }
    },
    methods: {
      handleClick () {
        document.getElementById('c-login-toggle').click()
      }
    },
    mounted () {
      /* eslint camelcase: 0 */
      const widget_id = 'pKWiHA4rQ6'
      const d = document
      const w = window

      function l () {
        const s = document.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = `//code.jivosite.com/script/widget/${widget_id}`
        const ss = document.getElementsByTagName('script')[0]
        ss.parentNode.insertBefore(s, ss)
      }

      if (d.readyState === 'complete') {
        l()
      } else {
        if (w.attachEvent) {
          w.attachEvent('onload', l)
        } else {
          w.addEventListener('load', l, false)
        }
      }

      /* eslint no-undef: 0 */
      window.dataLayerYandex = window.dataLayerYandex || [];
      (function (m, e, t, r, i, k, a) {
        m[i] = m[i] || function () {
          (m[i].a = m[i].a || []).push(arguments)
        }
        m[i].l = 1 * new Date()
        k = e.createElement(t)
        a = e.getElementsByTagName(t)[0]
        k.async = 1
        k.src = r
        a.parentNode.insertBefore(k, a)
      })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

      ym(process.env.ymId, 'init', {
        id: process.env.ymId,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        ecommerce: 'dataLayerYandex'
      })

      /* retail-rocket api main script */
      window.rrPartnerId = process.env.rrId;
      window.rrApi = {};
      window.rrApiOnReady = window.rrApiOnReady || [];
      rrApi.addToBasket = rrApi.order = rrApi.categoryView = rrApi.view = rrApi.recomMouseDown = rrApi.recomAddToCart = rrApi.setEmail = rrApi.search = function () {};
      (function (d) {
        var ref = d.getElementsByTagName('script')[0];
        var apiJsId = 'rrApi-jssdk';
        if (d.getElementById(apiJsId)) return;
        var apiJs = d.createElement('script');
        apiJs.id = apiJsId;
        apiJs.async = true;
        apiJs.src = '//cdn.retailrocket.ru/content/javascript/tracking.js';
        ref.parentNode.insertBefore(apiJs, ref);
      }(document));
    }
  }
</script>

<template lang="html">
    <div class="l-default v-layout">
        <header-container></header-container>
        <div class="v-page-wrapper">
            <nuxt></nuxt>
        </div>
        <footer-container></footer-container>
    </div>
</template>

<style lang="scss" scoped>
    @import '~assets/scss/variables';
    @import '~assets/scss/mixins';

    .v-layout {
        background: $sct-background-image;
    }

    .v-page-wrapper {
    }

    .u-message {
        @include clearfix;
        background-color: $brand-warning;
        border-color: $brand-warning;
        color: $gray-light;
        margin: 0;
        text-align: center;

        .text {
            margin-bottom: 0;
            padding: $spacer / 2 0;
        }
    }

    .loading {
        background: $sct-background-color-light;
        border-color: $sct-background-color-light;
        color: $sct-background-color-light;
        margin: 0;
        height: rem(5px);
    }

    .v-page-wrapper {
        @include clearfix;
        @include media-breakpoint-only('xl') {
            min-height: calc(100vh - #{rem(169.167 + 92 + 6px)});
        }
        @include media-breakpoint-only('lg') {
            min-height: calc(100vh - #{rem(244.95 + 90.95 + 6px)});
        }
        @include media-breakpoint-only('md') {
            min-height: calc(100vh - #{rem(257.95 + 90.95 + 6px)});
        }
        @include media-breakpoint-only('sm') {
            min-height: calc(100vh - #{rem(230.967 + 127.917 + 6px, 13px)});
        }
        @include media-breakpoint-only('xs') {
            min-height: calc(100vh - #{rem(377.25 + 176.667 + 6px, 13px)});
        }
        background: $sct-gradient-top;
    }
</style>
