<script>
  export default {
    name: 'LogoComponent'
  }
</script>

<template lang="html">
    <div class="c-logo v-component">
        <div class="row" :class="bem('phone')">
            <div class="col-12">
                <nuxt-link class="link" exact="exact" to="/">
                    <i class="i-png home"></i>
                    Главная
                </nuxt-link>
                <span class="hidden-sm-down phone">+7 (499) 404-05-66</span>
                <span class="hidden-sm-down phone">+7 (473) 220-42-20</span>
            </div>
        </div>
        <div class="row" :class="bem('logo')">
            <div class="col-12">
                <nuxt-link exact="exact" to="/">
                    <img class="image" src="~assets/images/logo-new.png" alt=""/>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~assets/scss/variables';
    @import '~assets/scss/mixins';

    // $debug: true;

    @include component('logo') {
        @include element('phone') {
            .link {
                @include hfas('active') {
                    color: $brand-primary;
                }
                color: $gray-light;
                float: left;

                i {
                    margin-right: $spacer / 3;
                }
            }
            .phone {
                @include media-breakpoint-down('sm') {
                    width: 100%;
                }
                display: block;
                font-weight: bold;
                max-width: 100%;
                text-align: right;
                width: rem(220px);
            }
        }
        @include element('logo') {
            .image {
                @include media-breakpoint-up('sm') {
                    margin-bottom: $spacer;
                }
                @include media-breakpoint-down('sm') {
                    margin-top: $spacer;
                }
                margin-top: $spacer / 2;
                max-width: 100%;
                width: rem(220px);
            }
        }
        &.v-component {
        }
    }
</style>
