<script>
  import { mapGetters, mapActions } from 'vuex'
  import LoginComponent from '~/components/Header/LoginComponent'

  export default {
    name: 'TopMenuComponent',
    components: {
      LoginComponent
    },
    data () {
      return {
        shown: false
      }
    },
    computed: {
      ...mapGetters('user', ['info', 'token', 'isAuthorizedUser', 'fullName'])
    },
    methods: {
      ...mapActions(['login']),
      ...mapActions('user', ['setToken', 'logout', 'authByToken'])
    },
    mounted () {
      if (this.$route.query.token) {
        this.authByToken(this.$route.query.token).then(this.setToken(this.$route.query.token)).then(this.login)
      } else {
        this.login()
      }
    }
  }
</script>

<template lang="html">
    <div class="c-top-menu v-component">
        <div class="row">
            <div class="col-7 hidden-md-down">
                <div class="row" :class="bem('menu')">
                    <div class="col-lg-12">
                        <ul class="menu">
                            <li class="item">
                                <nuxt-link class="link" to="/static/about/" exact="exact">О компании</nuxt-link>
                            </li>
                            <li class="item">
                                <nuxt-link class="link" to="/contact/" exact="exact">Контакты</nuxt-link>
                            </li>
                            <li class="item">
                                <nuxt-link class="link" to="/static/delivery/" exact="exact">Доставка и оплата
                                </nuxt-link>
                            </li>
                            <li class="item">
                                <nuxt-link class="link" to="/partners/" exact="exact">Стать клиентом</nuxt-link>
                            </li>
                            <li class="item">
                                <nuxt-link class="link" to="/feedback/" exact="exact">Отзывы</nuxt-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-5">
                <div class="row" :class="bem('user')">
                    <div class="col-8 offset-4 col-sm-12 offset-sm-0 wrapper">
                        <a class="login"
                           id="c-login-toggle"
                           href="#c-login"
                           v-show="!isAuthorizedUser"
                           @click.prevent="shown = !shown">
                            <i class="i-png lock"></i>
                            <span>Вход / Регистрация</span>
                        </a>
                        <nuxt-link class="link"
                                   exact="exact"
                                   to="/personal"
                                   v-show="(isAuthorizedUser)">
                            <i class="i-png lock"></i>
                            <span>{{ fullName }}</span>
                        </nuxt-link>
                        <a class="link divided"
                           id="c-logout"
                           href="#"
                           v-if="(isAuthorizedUser)"
                           @click.prevent="logout()">
                            <i class="i-png logout"></i>
                            <span class="hidden-xs-down">Выход</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <login-component :shown="shown" @close="shown = false"></login-component>
    </div>
</template>

<style lang="scss" scoped>
    @import '~assets/scss/variables';
    @import '~assets/scss/mixins';
    // $debug: true;
    @include component('top-menu') {
        @include element('menu') {
            .menu {
                @include clearfix;

                list-style: none;
                margin-bottom: 0;
                padding: 0;

                .item {
                    display: inline-block;
                    float: left;
                    margin-right: $spacer;

                    &:last-child {
                        margin-right: 0;
                    }

                    .link {
                        @include hfas('active') {
                            color: $brand-primary;
                        }

                        color: $gray-light;
                        text-decoration: underline;
                    }
                }
            }
        }
        @include element('user') {
            text-align: right;
            .wrapper {
                @include media-breakpoint-down('sm') {
                    line-height: 1.5;
                    text-align: right;
                    width: 100%;
                }
                @include media-breakpoint-down('xs') {
                    bottom: $spacer / 2;
                    margin-bottom: rem(57px);
                    position: absolute;
                }

                .link {
                    display: inline-block;
                    width: auto;

                    &:first-child {
                        @include media-breakpoint-down('sm') {
                            margin-right: 0;
                        }

                        margin-right: $spacer;
                    }

                    i {
                        margin-right: $spacer / 3;
                    }
                }

                .divided {
                    margin-left: $spacer * 1.5;
                    position: relative;

                    &::before {
                        content: '/';
                        display: inline;
                        position: absolute;
                        top: 0;
                        left: -$spacer;
                    }
                }
            }
        }
        &.v-component {
        }
    }
</style>
