<script>
  import VueRecaptcha from 'vue-recaptcha'

  var timer = null

  export default {
    name: 'FormComponent',
    components: {
      VueRecaptcha
    },
    props: {
      form: {
        type: Object,
        default: () => {
          return {}
        }
      },
      btnText: {
        type: String,
        default: 'Отправить'
      },
      btnTextLoading: {
        type: String,
        default: 'Обработка'
      },
      btnTooltip: {
        type: String,
        default: ''
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      captcha: {
        type: Boolean,
        default: true
      },
      showProgress: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        showDirtyFields: false,
        sitekey: '6LfGth0UAAAAALK8i5enrrSd0qF6hMuoW28Tc7GN',
        warns: {
          empty: 'Поле не может быть пустым',
          emptyPlace: 'Пожалуйста, выберите адрес из выпадающего списка',
          loading: 'Обработка'
        },
        formData: {},
        progress: 0
      }
    },
    computed: {
      formValues () {
        var keys = (this.formData) ? Object.keys(this.formData) : []
        var result = {}
        for (var i in keys) {
          var field = this.formData[keys[i]]
          result[keys[i]] = this.getFieldValue(field)
        }
        return result
      },
      formState () {
        var keys = (this.formData) ? Object.keys(this.formData) : []
        var result = {}
        for (var i in keys) {
          var field = this.formData[keys[i]]
          result[keys[i]] = field.state
        }
        return result
      },
      hasGoodData () {
        var keys = (this.formData) ? Object.keys(this.formData) : []
        for (var i in keys) {
          if (this.formState[keys[i]]) {
            if (!this.formState[keys[i]].valid || this.formState[keys[i]].empty) {
              return false
            }
          } else {
            return false
          }
        }
        return true
      }
    },
    watch: {
      formValues () {
        var keys = (this.formData) ? Object.keys(this.formData) : []
        for (var i in keys) {
          var field = this.formData[keys[i]]
          var result = {}

          // Validation
          if ((typeof (field.validation) === 'function')) {
            // no undef
            (!field.value)
              ? result.valid = false
              // valid
              : (field.validation(field.value)) ? result.valid = true : result.valid = false
          } else {
            result.valid = true
          }

          // Empty
          if (field.required === true) {
            // no undef
            (!field.value)
              ? result.empty = true : ((field.value || 0) === field.value.length)
                ? result.empty = true : result.empty = false
          } else {
            result.empty = false
          }

          // Default
          if (field.value === this.getDefaultValue(keys[i])) {
            result.default = true
          } else {
            result.default = false
          }

          this.$set(
            this.formData[keys[i]],
            'state',
            result
          )
        }
      },
      form () {
        this.syncFormData()
      },
      isLoading () {
        if (this.isLoading && this.showProgress) {
          this.progress = 10
          timer = setInterval(() => {
            this.progress += (100 - this.progress) * 0.03
          }, 500)
        } else {
          this.progress = 100
          clearInterval(timer)
          setTimeout(() => {
            this.progress = 0
          }, 2000)
        }
      }
    },
    methods: {
      hasDanger (field) {
        if (this.showDirtyFields) {
          return (!field.state || !(field.state.valid || field.state.empty))
        } else {
          return false
        }
      },
      hasWarning (field) {
        if (this.showDirtyFields) {
          return (!field.state || field.state.empty)
        } else {
          return false
        }
      },
      hasSuccess (field) {
        if (this.showDirtyFields) {
          return (!field.state || field.state.valid)
        } else {
          return false
        }
      },
      getDefaultValue (field) {
        switch (field.type) {
          case Object:
            return field.default()
          default:
            return field.default
        }
      },
      getFieldValue (field) {
        let value = (field.value) ? field.value : this.getDefaultValue(field)
        switch (field.fieldType) {
          case 'phone':
            return this.formatPhoneNumber(value)
          default:
            return value
        }
      },
      getFieldClassList (field) {
        if (field.class) {
          return field.class
        } else {
          return ['col-12']
        }
      },
      formatPhoneNumber (s) {
        var s2 = ('' + s).replace(/\D/g, '')
        var m = s2.match(/^(\d)(\d{3})(\d{3})(\d{4})$/)
        return (!m) ? null : `+${m[1]} (${m[2]}) ${m[3]}-${m[4]}`
      },
      syncFormData () {
        const keys = (this.form) ? Object.keys(this.form) : []
        this.formData = {}
        for (const i in keys) {
          this.$set(this.formData, keys[i], this.form[keys[i]])
          let field = this.formData[keys[i]]
          this.$set(this.formData[keys[i]], 'value', this.getFieldValue(field))
        }
      },
      handleSubmit () {
        this.showDirtyFields = true

        if (this.hasGoodData) {
          this.$emit('submit', this.formValues)
        } else {
          this.$toasted.error(`Не все поля формы заполнены верно`, { position: 'top-right', duration: 5000 })
        }
      },
      handleSubmitWithCaptcha (response) {
        this.showDirtyFields = true

        if (this.hasGoodData) {
          let formValues = {
            captcha: response,
            ...this.formValues
          }
          this.$emit('submit', formValues)
        } else {
          this.$toasted.error(`Не все поля формы заполнены верно`, { position: 'top-right', duration: 5000 })
        }
        this.$refs.invisibleRecaptcha.reset()
      },
      canSubmit () {
        return !((!this.hasGoodData && this.showDirtyFields) || this.isLoading)
      },
      onSubmit () {
        if (this.captcha) {
          this.$refs.invisibleRecaptcha.execute()
        } else {
          this.handleSubmit()
        }
      },
      onExpired () {
        this.$refs.invisibleRecaptcha.reset()
      }
    },
    mounted () {
      this.syncFormData()
    }
  }
</script>

<template lang="html">
    <form class="c-form v-component" @submit.prevent="onSubmit()">
        <div class="progress" v-if="progress > 0 && showProgress">
            <div class="col-12">
                <vm-progress :percentage="progress"
                             type="circle"
                             stroke-color="#0275d8"
                             :show-text="false"
                             track-color="rgba(2, 117, 216, 0.2)">
                </vm-progress>
            </div>
        </div>
        <div class="row">
            <slot class="col-12" name="header"></slot>
        </div>
        <div class="row">
            <div v-if="formData" v-for="field, key in formData" :class="getFieldClassList(field)" :key="key">
                <fieldset class="form-group"
                          v-if="field.fieldType === 'text' && field !== undefined"
                          :class="{
                              'has-danger': hasDanger(field) || hasWarning(field),
                              'has-success': hasSuccess(field)
                          }">
                    <label class="label">{{ field.label }}:</label>
                    <input class="form-control"
                           :class="{
                               'form-control-danger': hasDanger(field) || hasWarning(field),
                               'form-control-success': hasSuccess(field)
                           }"
                           type="text"
                           v-model="field.value"
                           :name="key"/>
                    <small class="text-danger small" v-if="hasDanger(field)">{{ field.danger }}</small>
                    <small class="text-danger small" v-if="hasWarning(field)">{{ warns.empty }}</small>
                </fieldset>
                <fieldset class="form-group"
                          v-if="field.fieldType === 'password' && field !== undefined"
                          :class="{
                              'has-danger': hasDanger(field) || hasWarning(field),
                              'has-success': hasSuccess(field)
                          }">
                    <label class="label">{{ field.label }}:</label>
                    <input class="form-control"
                           :class="{
                               'form-control-danger': hasDanger(field) || hasWarning(field),
                               'form-control-success': hasSuccess(field)
                           }"
                           type="password"
                           v-model="field.value"
                           :name="key"/>
                    <small class="text-danger small" v-if="hasDanger(field)">{{ field.danger }}</small>
                    <small class="text-danger small" v-if="hasWarning(field)">{{ warns.empty }}</small>
                </fieldset>
                <fieldset class="form-group"
                          v-if="field.fieldType === 'select' && field !== undefined"
                          :class="{
                              'has-danger': hasDanger(field) || hasWarning(field),
                              'has-success': hasSuccess(field)
                          }">
                    <label class="label">{{ field.label }}:</label>
                    <select class="custom-select form-control"
                            :class="{
                                'form-control-danger': hasDanger(field) || hasWarning(field),
                                'form-control-success': hasSuccess(field)
                            }"
                            v-if="field"
                            v-model="field.value"
                            :name="key">
                        <option v-for="option in field.options" :value="option.value">{{ option.title }}</option>
                        <small class="text-danger small" v-if="hasDanger(field)">{{ field.danger }}</small>
                        <small class="text-danger small" v-if="hasWarning(field)">{{ warns.empty }}</small>
                    </select>
                </fieldset>
                <fieldset class="form-group"
                          v-if="field.fieldType === 'phone' && field !== undefined"
                          :class="{
                              'has-danger': hasDanger(field) || hasWarning(field),
                              'has-success': hasSuccess(field)
                          }">
                    <label class="label">{{ field.label }}:</label>
                    <masked-input class="form-control"
                                  ref="phoneInput"
                                  :class="{
                                      'form-control-danger': hasDanger(field) || hasWarning(field),
                                      'form-control-success': hasSuccess(field)
                                  }"
                                  type="tel"
                                  v-if="field"
                                  :mask="['+', '7', ' ', '(', /[1-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]"
                                  :guide="false"
                                  v-model="field.value"
                                  placeholder="+7"
                                  :name="key">
                    </masked-input>
                    <small class="text-danger small" v-if="hasDanger(field)">{{ field.danger }}</small>
                    <small class="text-danger small" v-if="hasWarning(field)">{{ warns.empty }}</small>
                </fieldset>
                <fieldset class="form-group"
                          v-if="field.fieldType === 'textarea' && field !== undefined"
                          :class="{
                              'has-danger': hasDanger(field) || hasWarning(field),
                              'has-success': hasSuccess(field)
                          }">
                    <label class="label">{{ field.label }}:</label>
                    <textarea class="form-control" rows="5"
                              :class="{
                                  'form-control-danger': hasDanger(field) || hasWarning(field),
                                  'form-control-success': hasSuccess(field)
                              }"
                              type="text"
                              v-model="field.value"
                              :name="key">
                    </textarea>
                    <small class="text-danger small" v-if="hasDanger(field)">{{ field.danger }}</small>
                    <small class="text-danger small" v-if="hasWarning(field)">{{ warns.empty }}</small>
                </fieldset>
                <fieldset class="form-group"
                          v-if="field.fieldType === 'email' && field !== undefined"
                          :class="{
                              'has-danger': hasDanger(field) || hasWarning(field),
                              'has-success': hasSuccess(field)
                          }">
                    <label class="label">{{ field.label }}:</label>
                    <input class="form-control"
                           :class="{
                               'form-control-danger': hasDanger(field) || hasWarning(field),
                               'form-control-success': hasSuccess(field)
                           }" type="email"
                           v-if="field"
                           v-model="field.value"
                           :name="key"/>
                    <small class="text-danger small" v-if="hasDanger(field)">{{ field.danger }}</small>
                    <small class="text-danger small" v-if="hasWarning(field)">{{ warns.empty }}</small>
                </fieldset>
                <fieldset class="form-group"
                          v-if="field.fieldType === 'checkbox' && field !== undefined"
                          :class="{
                              'has-danger': hasDanger(field) || hasWarning(field),
                              'has-success': hasSuccess(field)
                          }">
                    <label class="label custom-control custom-checkbox mb-0">
                        <input class="custom-control-input"
                               :class="{
                                   'form-control-danger': hasDanger(field) || hasWarning(field),
                                   'form-control-success': hasSuccess(field)
                               }"
                               type="checkbox"
                               v-if="field"
                               v-model="field.value"
                               :name="key"/>
                        <span class="custom-control-indicator"></span>
                        <span class="custom-control-description">{{ field.label }}</span>
                        <small class="text-danger small ml-4" v-if="hasDanger(field)">{{ field.danger }}</small>
                        <small class="text-danger small ml-4" v-if="hasWarning(field)">{{ warns.empty }}</small>
                    </label>
                </fieldset>
            </div>
        </div>
        <div class="row">
            <slot class="col-12" name="footer"></slot>
        </div>
        <div class="row">
            <div class="col-12 text-right">
                <button class="btn btn-primary mt-3"
                        ref="submitBtn"
                        type="submit"
                        v-if="canSubmit() && !disabled">
                    {{ btnText }}
                </button>
                <span v-else-if="disabled"
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="btnTooltip">
                    <button class="btn btn-primary disabled mt-3"
                            ref="submitBtn"
                            type="submit"
                            disabled="disabled">
                        {{ btnText }}
                    </button>
                </span>
                <button class="btn btn-primary disabled mt-3"
                        ref="submitBtn"
                        type="submit"
                        v-else
                        disabled="disabled">
                    {{ (!this.hasGoodData) ? btnText : btnTextLoading }}
                </button>
            </div>
        </div>
        <div class="row" v-show="false" v-if="captcha">
            <div class="col-12">
                <vue-recaptcha class="float-right mt-3"
                               ref="invisibleRecaptcha"
                               @verify="handleSubmitWithCaptcha"
                               @expired="onExpired"
                               size="invisible"
                               :sitekey="sitekey"
                               badge="inline">
                </vue-recaptcha>
            </div>
        </div>
    </form>
</template>


<style lang="scss">
    @import '~assets/scss/mixins';

    .vm-progress {
        @include centerer(true, true);
        position: absolute !important;
        z-index: 100;
    }

    .progress {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 99;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $brand-primary-lightest;
        border-radius: $border-radius;
    }
</style>

<style lang="scss" scoped>
    @import '~assets/scss/mixins';
    // $debug: true;
    @include component('form') {
        width: 100%;
        .label {
            color: $gray-light;
        }
        .small {
            display: block;
            line-height: 1.3;
            margin-top: $spacer / 4;

            &.text-danger + .text-danger {
                display: none;
            }
        }
        &.v-component {
        }
    }
</style>


