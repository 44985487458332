export const state = () => ({
  sections: [],
  products: [],
  selected: []
})

export const getters = {
  search (state) {
    return state.products
  },
  selected (state) {
    return state.selected
  }
}

export const actions = {
  updateState (store, query) {
    // TODO: change method name to more suitable
    return new Promise((resolve, reject) => {
      const params = {
        q: query
      }
      this.$axios.$get('/search/quick/', { params })
        .then(data => {
          store.commit('UPDATE_STATE', data)
          resolve(data)
        })
        .catch((response) => {
          resolve(response)
        })
    })
  },
  addToSelected (store, code) {
    return new Promise((resolve, reject) => {
      const params = {
        ELEMENT_ID: code
      }
      this.$axios.$get('/catalog/', { params: params }).then(data => {
        if (data.product) {
          const product = data.product
          delete product.inBasket
          delete product.images
          delete product.description
          delete product.basketQuantity
          store.commit('ADD_TO_SELECTED', product)
          resolve(data)
        } else {
          reject(code)
        }
      })
    })
  },
  removeFromSelected (store, code) {
    store.commit('REMOVE_FROM_SELECTED', code)
  },
  clearSelected (store) {
    store.commit('CLEAR_SELECTED')
  }
}

export const mutations = {
  UPDATE_STATE (state, data) {
    state.sections = data.sections
    state.products = data.products
  },
  ADD_TO_SELECTED (state, product) {
    if (state.selected.filter((i) => {
      return i.code === product.code
    }).length === 0) {
      state.selected.push(product)
    }
  },
  REMOVE_FROM_SELECTED (state, code) {
    let product = state.selected.filter((i) => {
      return i.code === code
    })
    state.selected.splice(state.selected.indexOf(product), 1)
  },
  CLEAR_SELECTED (state) {
    state.selected = []
  }
}
