import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _2d1cfa2d = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _57989848 = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "pages/career" */))
const _664c886e = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _c861f76a = () => interopDefault(import('../pages/code.vue' /* webpackChunkName: "pages/code" */))
const _2c22129c = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _7be7b849 = () => interopDefault(import('../pages/excel.vue' /* webpackChunkName: "pages/excel" */))
const _b8f1ba3a = () => interopDefault(import('../pages/feedback.vue' /* webpackChunkName: "pages/feedback" */))
const _ef97ae04 = () => interopDefault(import('../pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _702f958c = () => interopDefault(import('../pages/logos.vue' /* webpackChunkName: "pages/logos" */))
const _0c87c960 = () => interopDefault(import('../pages/order.vue' /* webpackChunkName: "pages/order" */))
const _1516f109 = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _3eddd784 = () => interopDefault(import('../pages/pdf.vue' /* webpackChunkName: "pages/pdf" */))
const _9b09ffbe = () => interopDefault(import('../pages/personal/index.vue' /* webpackChunkName: "pages/personal/index" */))
const _7cf8be8e = () => interopDefault(import('../pages/suppliers.vue' /* webpackChunkName: "pages/suppliers" */))
const _5e918ac5 = () => interopDefault(import('../pages/personal/orders/_page.vue' /* webpackChunkName: "pages/personal/orders/_page" */))
const _76240486 = () => interopDefault(import('../pages/brands/_brand.vue' /* webpackChunkName: "pages/brands/_brand" */))
const _68173968 = () => interopDefault(import('../pages/catalog/_code.vue' /* webpackChunkName: "pages/catalog/_code" */))
const _171c0182 = () => interopDefault(import('../pages/commingsoon/_page.vue' /* webpackChunkName: "pages/commingsoon/_page" */))
const _1cecfb6c = () => interopDefault(import('../pages/detail/_code.vue' /* webpackChunkName: "pages/detail/_code" */))
const _ec024c10 = () => interopDefault(import('../pages/kits/_code.vue' /* webpackChunkName: "pages/kits/_code" */))
const _994c7dbc = () => interopDefault(import('../pages/search/_query.vue' /* webpackChunkName: "pages/search/_query" */))
const _98f7f62a = () => interopDefault(import('../pages/static/_page.vue' /* webpackChunkName: "pages/static/_page" */))
const _1885ee38 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'is-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/brands",
      component: _2d1cfa2d,
      name: "brands"
    }, {
      path: "/career",
      component: _57989848,
      name: "career"
    }, {
      path: "/catalog",
      component: _664c886e,
      name: "catalog"
    }, {
      path: "/code",
      component: _c861f76a,
      name: "code"
    }, {
      path: "/contact",
      component: _2c22129c,
      name: "contact"
    }, {
      path: "/excel",
      component: _7be7b849,
      name: "excel"
    }, {
      path: "/feedback",
      component: _b8f1ba3a,
      name: "feedback"
    }, {
      path: "/kits",
      component: _ef97ae04,
      name: "kits"
    }, {
      path: "/logos",
      component: _702f958c,
      name: "logos"
    }, {
      path: "/order",
      component: _0c87c960,
      name: "order"
    }, {
      path: "/partners",
      component: _1516f109,
      name: "partners"
    }, {
      path: "/pdf",
      component: _3eddd784,
      name: "pdf"
    }, {
      path: "/personal",
      component: _9b09ffbe,
      name: "personal"
    }, {
      path: "/suppliers",
      component: _7cf8be8e,
      name: "suppliers"
    }, {
      path: "/personal/orders/:page?",
      component: _5e918ac5,
      name: "personal-orders-page"
    }, {
      path: "/brands/:brand",
      component: _76240486,
      name: "brands-brand"
    }, {
      path: "/catalog/:code",
      component: _68173968,
      name: "catalog-code"
    }, {
      path: "/commingsoon/:page?",
      component: _171c0182,
      name: "commingsoon-page"
    }, {
      path: "/detail/:code?",
      component: _1cecfb6c,
      name: "detail-code"
    }, {
      path: "/kits/:code",
      component: _ec024c10,
      name: "kits-code"
    }, {
      path: "/search/:query?",
      component: _994c7dbc,
      name: "search-query"
    }, {
      path: "/static/:page?",
      component: _98f7f62a,
      name: "static-page"
    }, {
      path: "/",
      component: _1885ee38,
      name: "index"
    }],

    fallback: false
  })
}
