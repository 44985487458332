<script>
  import LogoComponent from '~/components/Header/LogoComponent'
  import TopMenuComponent from '~/components/Header/TopMenuComponent'
  import SearchComponent from '~/components/Header/SearchComponent'
  import BasketComponent from '~/components/Header/BasketComponent'
  import CatalogButtonComponent from '~/components/Header/CatalogButtonComponent'
  import BottomMenuComponent from '~/components/Header/BottomMenuComponent'
  import CatalogComponent from '~/components/Header/CatalogComponent'

  export default {
    name: 'HeaderContainer',
    components: {
      LogoComponent,
      TopMenuComponent,
      SearchComponent,
      BasketComponent,
      CatalogButtonComponent,
      BottomMenuComponent,
      CatalogComponent
    },
    data () {
      return {
        showCatalog: false
      }
    }
  }
</script>

<template lang="html">
    <div class="b-header v-container">
        <div class="container-fluid">
            <div class="row" :class="bem('container')">
                <div class="col-12">
                    <div class="row" :class="bem('panel')">
                      <div class="container">
                        <div class="row">
                          <span class="m-auto p-2 h5">
                            <nuxt-link class="link" to="/static/delivery/" exact="exact">
                              Важные правила по работе с оптовыми клиентами
                            </nuxt-link>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row" :class="bem('top')">
                        <div class="container">
                            <div class="row">
                                <logo-component class="col-12 col-sm-6 col-md-4 col-lg-3"></logo-component>
                                <div class="col-12 col-sm-6 col-md-8 col-lg-9">
                                    <div class="row">
                                        <top-menu-component class="col-12"></top-menu-component>
                                    </div>
                                    <div class="row">
                                        <search-component class="col-12 col-lg-8 col-xl-9"></search-component>
                                        <div class="col-lg-4 col-xl-3 hidden-md-down">
                                            <div class="row">
                                                <basket-component class="col-12"></basket-component>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" :class="bem('bottom')">
                        <div class="container">
                            <div class="row">
                                <catalog-button-component class="col col-sm-6 col-md-4 col-lg-3"
                                                          @click="showCatalog = !showCatalog">
                                </catalog-button-component>
                                <basket-component class="col-12 col-sm-6 col-md-8 hidden-lg-up hidden-xs-down">
                                </basket-component>
                                <bottom-menu-component class="col col-sm-6 col-md-8 col-lg-9 hidden-lg-down">
                                </bottom-menu-component>
                            </div>
                        </div>
                    </div>
                    <div class="row" :class="bem('catalog')" v-show="showCatalog">
                        <catalog-component class="col-12"
                                           v-if="showCatalog"
                                           :show="showCatalog"
                                           @close="showCatalog = false">
                        </catalog-component>
                    </div>
                    <div class="row" :class="bem('bottom')">
                        <div class="container">
                            <div class="row">
                                <bottom-menu-component class="col-12 hidden-xl-up"></bottom-menu-component>
                            </div>
                            <div class="row hidden-sm-up">
                                <basket-component class="col-12"></basket-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~assets/scss/variables';
    @import '~assets/scss/mixins';
    // $debug: true;
    @include block('header') {
        @include element('container') {
            @include component('search') {
                @include media-breakpoint-only('xl') {
                    margin-top: 2.3rem;
                    padding-right: $spacer * 4;
                }
                @include media-breakpoint-only('lg') {
                    margin-top: .9rem;
                    padding-right: $spacer * 2;
                }
                @include media-breakpoint-only('md') {
                    margin-top: 2.3rem;
                }
                @include media-breakpoint-only('sm') {
                    margin-top: 1.5rem;
                }
                @include media-breakpoint-only('xs') {
                    margin-top: $spacer;
                }
            }
            box-shadow: 0 rem(15px) rem(40px) rem(10px) rgba($gray, 0.05);
        }
        @include element('top') {
            @include media-breakpoint-only('xs') {
                padding-top: $spacer / 2;
            }
            background-image: linear-gradient(180deg, $sct-background-color-light 0%, $sct-background-color-light-transparent 100%);
            border-bottom: 1px solid darken($gray-lighter, 5%);
            font-size: rem(13px);
            padding-bottom: $spacer / 2;
            padding-top: $spacer;
        }
        @include element('bottom') {
            background: $sct-background-color-dark;
            @include media-breakpoint-down('lg') {
                > .container {
                    margin-top: -1px;
                }
            }
        }
        @include element('catalog') {
            border-top-style: solid;
            height: auto;
            overflow: visible;
            border: 1px solid darken($gray-lighter, 5%);
            border-left-style: none;
            border-right-style: none;
            border-top-style: none;
        }
        &.v-container {
        }
    }
</style>
