import createMutationsSharer from 'vuex-shared-mutations'
import AnalyticsHelper from '~/helpers/analytics-helper'

export const plugins = [
  createMutationsSharer({
    predicate: (mutation, state) => {
      const predicate = [
        'user/SET_OPTION',
        'user/UPDATE_TOKEN_AND_INFO',
        'user/SET_INFO_FIELD',
        'user/UPDATE_BASKET',
        'user/CLEAN_BASKET',
        'user/UPDATE_TOKEN',
        'user/ADD_BASKET_PRODUCT',
        'user/CHANGE_BASKET_PRODUCT',
        'user/REMOVE_BASKET_PRODUCT',
        'user/UPDATE_ORDERS_LIST',
        'user/GET_ORDER'
      ]

      return predicate.indexOf(mutation.type) >= 0
    }
  })
]

export const state = () => ({
  msg: 'State',
  baseURL: process.env.backendURL
  // strict: process.env.NODE_ENV !== 'production'
})

export const getters = {
  baseURL (state) {
    return state.baseURL
  }
}

export const actions = {
  nuxtServerInit ({ commit }, context) {
    commit('user/INIT_SETTINGS_FROM_URL', context);
  },
  priceRangeChange: ({ commit }, values) => {
    commit('PRICE_RANGE_CHANGE', values)
  },
  priceBlockToggle: ({ commit }) => {
    commit('PRICE_BLOCK_TOGGLE')
  },
  filterBlockToggle: ({ commit }, block) => {
    commit('FILTER_BLOCK_TOGGLE', block)
  },
  filterOptionChange: ({ commit }, { block, option }) => {
    commit('FILTER_OPTION_CHANGE', { block, option })
  },
  async login (store) {
    const token = (store.getters['user/token'] !== '') ? store.getters['user/token'] : (localStorage.token)
    const isAuthorizedUser = (store.getters['user/isAuthorizedUser'] === true) || (localStorage.isAuthorizedUser === true)
    store.commit('user/UPDATE_TOKEN', token, isAuthorizedUser)
    await store.dispatch('user/updateInfo')
    await store.dispatch('user/updateBasketFull')
    if (store.getters['user/info'].email) {
      AnalyticsHelper.retailRocketSetEmail(store.getters['user/info'].email, store.getters['user/info'].name)
    }
  }
}

export const mutations = {
  PRICE_RANGE_CHANGE (state, values) {
    state.catalog.filter.price.minHTML = parseInt(values[0])
    state.catalog.filter.price.maxHTML = parseInt(values[1])
  },
  PRICE_BLOCK_TOGGLE (state) {
    state.catalog.filter.price.shown = !state.catalog.filter.price.shown
  },
  FILTER_BLOCK_TOGGLE (state, block) {
    const index = state.catalog.filter.blocks.indexOf(block)
    state.catalog.filter.blocks[index].shown = !state.catalog.filter.blocks[index].shown
  },
  FILTER_OPTION_CHANGE (state, { block, option }) {
    const blockIndex = state.catalog.filter.blocks.indexOf(block)
    const optionIndex = state.catalog.filter.blocks[blockIndex].options.indexOf(option)
    state.catalog.filter.blocks[blockIndex].options[optionIndex].checked = !state.catalog.filter.blocks[blockIndex].options[optionIndex].checked
  }
}
