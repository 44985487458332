export const state = () => ({
  slides: []
})

export const mutations = {
  UPDATE_STATE (state, data) {
    state.slides = data.slides
  }
}

export const getters = {
  slides (state) {
    return state.slides
  }
}
