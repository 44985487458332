import AnalyticsHelper from '~/helpers/analytics-helper'

export const state = () => {
  return {
    info: {
      name: '',
      lastName: '',
      email: '',
      canUseCustomPrices: false
    },
    token: '',
    isAuthorizedUser: false,
    settings: {
      sort: {
        title: 'Cортировка',
        type: 'select',
        value: 'relevant',
        options: [
          {
            title: 'по умолчанию',
            value: 'relevant'
          },
          {
            title: 'дорогие вверх ↑',
            value: 'price_desc'
          },
          {
            title: 'дешёвые вверх ↑',
            value: 'price_asc'
          },
          {
            title: 'новые вверх ↑',
            value: 'date_desc'
          },
          {
            title: 'старые вверх ↑',
            value: 'date_asc'
          }
        ]
      },
      style: {
        title: 'Вид',
        type: 'buttons',
        value: 'row',
        options: [
          {
            title: 'Список',
            value: 'row'
          },
          {
            title: 'Плитка',
            value: 'block'
          }
        ]
      },
      quantity: {
        title: 'Количество',
        type: 'buttons',
        value: '30',
        options: [
          {
            title: '18',
            value: '18'
          },
          {
            title: '30',
            value: '30'
          },
          {
            title: '90',
            value: '90'
          }
        ]
      }
    },
    basket: {
      price: 0,
      quantity: [],
      weight: 0,
      products: [],
      areIndividualPricesApplied: false
    },
    orders: {
      list: [],
      full: [],
      currentOrderQuantity: {}
    }
  };
};

export const getters = {
  settings (state) {
    return state.settings
  },
  sort (state) {
    return state.settings.sort.value
  },
  quantity (state) {
    return parseInt(state.settings.quantity.value)
  },
  style (state) {
    return state.settings.style.value
  },
  orders (state) {
    return state.orders
  },
  orderFull (state) {
    return state.orders.full
  },
  currentOrderQuantity (state) {
    return state.orders.currentOrderQuantity;
  },
  basketPrice (state) {
    let result = 0
    for (let i = state.basket.products.length - 1; i >= 0; i--) {
      result += state.basket.products[i].price * state.basket.quantity[state.basket.products[i].code]
    }
    return result
  },
  basketWeight (state) {
    let result = 0
    for (let i = state.basket.products.length - 1; i >= 0; i--) {
      result += state.basket.products[i].weight * state.basket.quantity[state.basket.products[i].code]
    }
    return result
  },
  info (state) {
    return state.info
  },
  token (state) {
    return state.token
  },
  isAuthorizedUser (state) {
    return state.isAuthorizedUser
  },
  basket (state) {
    return state.basket;
  },

  basketProducts (state) {
    return state.basket.products
  },
  basketQuantity (state) {
    return state.basket.quantity
  },
  fullName (state) {
    if (state.info.name && state.info.lastName) {
      return state.info.name + ' ' + state.info.lastName
    } else if (state.info.name) {
      return state.info.name
    } else if (state.info.lastName) {
      return state.info.lastName
    } else {
      return ''
    }
  },
  name (state) {
    return state.info.name
  },
  lastName (state) {
    return state.info.lastName
  }
}

export const actions = {
  setOption (store, { key, value }) {
    store.commit('SET_OPTION', { key: key, value: value })
  },
  getOption (store, key) {
    return store.state.settings[key].value
  },
  login (store, params) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      for (var key in params) {
        data.append(key, params[key])
      }
      data.append('token', store.state.token)
      data.append('action', 'login')
      this.$axios.$post('/user/login/', data)
        .then(data => {
          if (data.success) {
            AnalyticsHelper.retailRocketSetEmail(data.info.email, data.info.name)
            store.commit('UPDATE_TOKEN_AND_INFO', {
              token: data.token,
              info: data.info,
              isAuthorizedUser: data.isAuthorizedUser
            })
            store.dispatch('updateBasket')
            resolve(data)
          } else {
            reject(data)
          }
        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },
  logout (store) {
    return new Promise((resolve) => {
      const data = new FormData()
      data.append('action', 'refresh_token')
      this.$axios.$post('/user/login/', data).then(data => {
        let basket = {
          price: 0,
          quantity: [],
          weight: 0,
          products: [],
          areIndividualPricesApplied: false
        }
        store.commit('UPDATE_TOKEN', data.token, false)
        store.commit('UPDATE_BASKET', { basket: basket })
        localStorage.lastOrderFormValues = ''
        resolve()
        this.$router.push('/')
      })
    })
  },
  register (store, params) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      for (var key in params) {
        data.append(key, params[key])
      }
      data.append('action', 'register')
      data.append('token', store.state.token)
      this.$axios.$post('/user/login/', data).then(data => {
        if (data.success) {
          store.commit('UPDATE_TOKEN_AND_INFO', { token: data.token, info: data.info, isAuthorizedUser: data.isAuthorizedUser })
          store.dispatch('updateBasket')
          AnalyticsHelper.retailRocketSetEmail(data.info.email, data.info.name)
          resolve(data)
        } else {
          reject(data)
        }
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },
  change (store, params) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      for (var key in params) {
        data.append(key, params[key])
      }
      data.append('action', 'change_user_info')
      this.$axios.$post('/forms/', data).then(data => {
        if (data.success) {
          store.commit('UPDATE_TOKEN_AND_INFO', { token: data.token, info: data.info, isAuthorizedUser: data.isAuthorizedUser })
          store.dispatch('updateBasket')
          resolve(data)
        } else {
          reject(data)
        }
      })
    })
  },
  restorePassword (store, params) {
    return new Promise((resolve, reject) => {
      params.action = 'restore_password'
      this.$axios.$get('/user/login/', { params: params }).then(data => {
        if (data.success) {
          resolve(data)
        } else {
          reject(data)
        }
      }).catch((error) => {
        reject(error.response.data)
      })
    })
  },
  setToken (store, token, isAuthorizedUser) {
    return new Promise((resolve, reject) => {
      store.commit('UPDATE_TOKEN', token, isAuthorizedUser)
      resolve()
    })
  },
  updateInfo (store) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('action', 'update_info')
      data.append('token', store.state.token)

      this.$axios.$post('/user/login/', data).then(data => {
        if (data.success) {
          store.commit('UPDATE_TOKEN_AND_INFO', { token: data.token, info: data.info, isAuthorizedUser: data.isAuthorizedUser })
          resolve(data)
        } else {
          store.commit('UPDATE_TOKEN', data.token, data.isAuthorizedUser)
          reject(data)
        }
      })
    })
  },
  authByToken (store, token) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('action', 'auth_by_token')
      data.append('token', token)

      this.$axios.$post('/user/login/', data).then(data => {
        if (data.success) {
          store.commit('UPDATE_TOKEN_AND_INFO', { token: data.token, info: data.info, isAuthorizedUser: data.isAuthorizedUser })
          resolve(data)
        } else {
          store.commit('UPDATE_TOKEN', data.token, data.isAuthorizedUser)
          reject(data)
        }
      })
    })
  },
  setInfoField (store, { key, value }) {
    store.commit('SET_INFO_FIELD', { key: key, value: value })
  },
  updateBasket (store) {
    const params = {
      action: 'get_basket_info',
      token: store.state.token,
      useIndividualPrices: store.state.basket.areIndividualPricesApplied
    };
    this.$axios.$get('/user/basket/', { params: params }).then(data => {
      if (data.success) {
        store.commit('UPDATE_BASKET', data);
      }
    })
  },
  cleanBasket (store) {
    const params = {
      action: 'clean_basket',
      token: store.state.token
    }
    this.$axios.$get('/user/basket/', { params: params }).then(data => {
      if (data.success) {
        store.commit('CLEAN_BASKET')
      }
    })
  },
  updateBasketFull (store) {
    const params = {
      action: 'get_basket_full',
      token: store.state.token,
      useIndividualPrices: store.state.basket.areIndividualPricesApplied
    };
    return this.$axios.$get('/user/basket/', { params: params })
      .then(data => {
        if (data.success) {
          store.commit('UPDATE_BASKET', data)
          return data;
        }

        throw data;
      });
  },
  updateOrdersList (store) {
    return new Promise((resolve, reject) => {
      const params = {
        action: 'get_orders_list',
        token: store.state.token
      }
      this.$axios.$get('/user/orders/', { params: params }).then(data => {
        if (data.success) {
          store.commit('UPDATE_AUTH', data.isAuthorizedUser)
          store.commit('UPDATE_ORDERS_LIST', data)
          resolve(data)
        }
      })
    })
  },
  getOrderById (store, key) {
    const params = {
      action: 'get_order',
      token: store.state.token,
      id: key
    }
    return this.$axios.$get('/user/orders/', { params: params })
      .then(data => {
        if (data.success) {
          store.commit('UPDATE_AUTH', data.isAuthorizedUser)
          store.commit('GET_ORDER', data)
          return data;
        }

        throw data;
      });
  },
  addByCode (store, { product, quantity }) {
    return new Promise((resolve, reject) => {
      const params = {
        action: 'add_by_code',
        token: store.state.token,
        code: product.code,
        quantity: quantity
      }
      this.$axios.$get('/user/basket/', { params: params }).then(data => {
        if (data.success === true) {
          store.commit('ADD_BASKET_PRODUCT', { product, quantity })
          resolve(data)
          AnalyticsHelper.addToBasket(product, quantity)
        } else {
          reject(data)
        }
      })
    })
  },
  changeByCode (store, { product, quantity, quantityPrev }) {
    return new Promise((resolve, reject) => {
      const params = {
        action: 'change_by_code',
        token: store.state.token,
        code: product.code,
        quantity: quantity
      }
      this.$axios.$get('/user/basket/', { params: params }).then(data => {
        if (data.success === true) {
          store.commit('CHANGE_BASKET_PRODUCT', { code: product.code, quantity })
          resolve(data)
          if (quantity > quantityPrev) {
            AnalyticsHelper.addToBasket(product, quantity - quantityPrev)
          } else {
            AnalyticsHelper.removeProductFromYaMetrika(product, quantityPrev - quantity)
          }
        } else {
          reject(data)
        }
      })
    })
  },
  removeByCode (store, { product, quantity }) {
    return new Promise((resolve, reject) => {
      const params = {
        action: 'remove_by_code',
        token: store.state.token,
        code: product.code
      }
      this.$axios.$get('/user/basket/', { params: params }).then(data => {
        if (data.success === true) {
          store.commit('REMOVE_BASKET_PRODUCT', product.code)
          resolve(data)
          AnalyticsHelper.removeProductFromYaMetrika(product, quantity)
        } else {
          reject(data)
        }
      })
    })
  },
  applyIndividualPrices ({ commit, dispatch }) {
    commit('SET_INDIVIDUAL_PRICES_FLAG', true);
    return dispatch('updateBasketFull');
  },
  revertIndividualPrices ({ commit, dispatch }) {
    commit('SET_INDIVIDUAL_PRICES_FLAG', false);
    return dispatch('updateBasketFull');
  }
}

export const mutations = {
  INIT_SETTINGS_FROM_URL (state, {query}) {
    Object.keys(query).forEach((key) => {
      if (key in state.settings) {
        state.settings[key].value = query[key];
      }
    });
  },
  SET_OPTION (state, { key, value }) {
    state.settings[key].value = value
  },
  UPDATE_TOKEN_AND_INFO (state, { token, info, isAuthorizedUser }) {
    state.token = token
    state.isAuthorizedUser = isAuthorizedUser
    state.info.name = info.name
    state.info.lastName = info.last_name
    state.info.email = info.email
    state.info.phone = info.phone
    state.info.canUseCustomPrices = info.canUseCustomPrices
    localStorage.token = token
    localStorage.isAuthorizedUser = isAuthorizedUser
  },
  SET_INFO_FIELD (state, { key, value }) {
    state.info[key] = value
  },
  UPDATE_BASKET (state, data) {
    state.basket.quantity = data.basket.quantity
    if (data.basket.products) {
      state.basket.products = data.basket.products
    }
    state.basket.weight = data.basket.weight
    state.basket.price = data.basket.price
  },
  CLEAN_BASKET (state) {
    state.basket = {
      price: 0,
      quantity: [],
      weight: 0,
      products: [],
      areIndividualPricesApplied: false
    }
  },
  UPDATE_TOKEN (state, token, isAuthorizedUser) {
    state.token = token
    localStorage.token = token

    state.isAuthorizedUser = isAuthorizedUser
    localStorage.isAuthorizedUser = isAuthorizedUser
  },
  UPDATE_AUTH (state, isAuthorizedUser) {
    state.isAuthorizedUser = isAuthorizedUser
    localStorage.isAuthorizedUser = isAuthorizedUser
  },
  ADD_BASKET_PRODUCT (state, { product, quantity }) {
    const basketQuantity = JSON.parse(JSON.stringify(state.basket.quantity))
    const d = new Date()
    const leftPad = (str) => {
      var len = (String(10).length - String(str).length) + 1
      return len > 0 ? new Array(len).join('0') + str : str
    }
    let _product = JSON.parse(JSON.stringify(product))
    _product.date = [d.getFullYear(), leftPad(d.getMonth() + 1), leftPad(d.getDate())].join('/') + ' ' + [leftPad(d.getHours()), leftPad(d.getMinutes()), leftPad(d.getSeconds())].join(':')
    state.basket.products.push(_product)
    state.basket.quantity = {
      [product.code]: quantity,
      ...basketQuantity
    }
  },
  CHANGE_BASKET_PRODUCT (state, { code, quantity }) {
    state.basket.quantity[code] = quantity
  },
  REMOVE_BASKET_PRODUCT (state, code) {
    const basketQuantity = JSON.parse(JSON.stringify(state.basket.quantity))
    delete basketQuantity[code]
    state.basket.quantity = basketQuantity
    state.basket.products = state.basket.products.filter((i) => {
      return (i.code !== code)
    })
  },
  UPDATE_ORDERS_LIST (state, data) {
    state.orders.list = data.list
  },
  GET_ORDER (state, data) {
    state.orders.full = data.products
    state.orders.currentOrderQuantity = typeof data.quantity === 'object' ? data.quantity : {};
  },
  /**
   * @param state
   * @param {boolean} areIndividualPricesApplied
   */
  SET_INDIVIDUAL_PRICES_FLAG (state, areIndividualPricesApplied) {
    state.basket.areIndividualPricesApplied = areIndividualPricesApplied;
  }
}
