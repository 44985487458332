export default class AnalyticsHelper {
  static addToBasket (product, quantity) {
    this.addProductToYaMetrika(product, quantity)
    this.retailRocketAddToBasket(product, quantity)
  }

  static showProductPage (product) {
    this.pushDetailToYaMetrika(product)
    this.retailRocketProductView(product)
  }

  static sendOrder (orderId, products, quantities) {
    this.sendOrderToAnalytics(orderId, products, quantities)
    this.retailRocketMakeOrder(orderId, products, quantities)
  }

  // Yandex Metrika block
  static _getYandexDataLayer () {
    return window['dataLayerYandex'] || []
  }

  static _getYaMetrikaProduct (product, quantity = 1) {
    let category = ''
    try {
      category = product.section.current.title
    } catch (err) {
      // TODO: add sentry exception capture
    }

    return {
      'id': product.id,
      'name': product.name,
      'price': product.price,
      'brand': product.brand,
      'quantity': quantity,
      'category': category
    }
  }

  static sendOrderToAnalytics (orderId, products, quantities) {
    let yaMetrikaProducts = products.map((product) => {
      return this._getYaMetrikaProduct(product, quantities[product.code])
    })
    this.pushOrderToYaMetrika(yaMetrikaProducts, orderId)
  }

  static pushOrderToYaMetrika (products, orderId) {
    window['dataLayerYandex'] = this._getYandexDataLayer()
    window['dataLayerYandex'].push({
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': orderId
          },
          'products': products
        }
      }
    })
  }

  static addProductToYaMetrika (product, quantity) {
    window['dataLayerYandex'] = this._getYandexDataLayer()
    window['dataLayerYandex'].push({
      'ecommerce': {
        'add': {
          'products': [this._getYaMetrikaProduct(product, quantity)]
        }
      }
    })
  }

  static removeProductFromYaMetrika (product, quantity) {
    window['dataLayerYandex'] = this._getYandexDataLayer()
    window['dataLayerYandex'].push({
      'ecommerce': {
        'remove': {
          'products': [this._getYaMetrikaProduct(product, quantity)]
        }
      }
    })
  }

  static pushDetailToYaMetrika (product) {
    window['dataLayerYandex'] = this._getYandexDataLayer()
    window['dataLayerYandex'].push({
      'ecommerce': {
        'detail': {
          'products': [this._getYaMetrikaProduct(product)]
        }
      }
    })
  }

  // RetailRocket scripts
  /* eslint no-undef: 0 */
  static getRetailRocketDataLayer () {
    return window['rrApiOnReady'] || []
  }

  static retailRocketProductView (product) {
    this.getRetailRocketDataLayer().push(function () {
      try {
        rrApi.view(product.id)
      } catch (e) {
      }
    })
  }

  static retailRocketCategoryView (category) {
    this.getRetailRocketDataLayer().push(function () {
      try {
        rrApi.categoryView(category.id)
      } catch (e) {
      }
    })
  }

  static retailRocketAddToBasket (product) {
    try {
      rrApi.addToBasket(product.id)
    } catch (e) {
    }
  }

  static retailRocketMakeOrder (orderId, products, quantities) {
    let retailRocketProducts = products.map((product) => {
      return {id: product.id, qnt: quantities[product.code], price: product.price}
    })

    this.getRetailRocketDataLayer().push(function () {
      try {
        rrApi.order({
          transaction: orderId,
          items: retailRocketProducts
        })
      } catch (e) {
      }
    })
  }

  static retailRocketSetEmail (email, name) {
    this.getRetailRocketDataLayer().push(function () {
      rrApi.setEmail(email, {name: name})
    })
  }

  static retailRocketDoSearch (searchPhrase) {
    this.getRetailRocketDataLayer().push(function () {
      rrApi.search(searchPhrase);
    })
  }
}
