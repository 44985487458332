<script>
  /* eslint no-useless-escape: 0 */
  import { mapActions, mapGetters } from 'vuex'
  import FormComponent from '~/components/Common/FormComponent'

  export default {
    name: 'LoginComponent',
    components: {
      FormComponent
    },
    props: {
      shown: Boolean
    },
    data () {
      return {
        loading: false,
        switchForms: 'login',
        isEntity: true
      }
    },
    computed: {
      ...mapGetters('user', ['info', 'token', 'name', 'lastName']),
      passwordRestoreForm () {
        return {
          email: {
            label: 'E-mail',
            fieldType: 'email',
            type: String,
            default: this.info.email,
            validation: (value) => {
              var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return re.test(value)
            },
            required: true,
            danger: 'Проверьте правильность введенного адреса'
          }
        }
      },
      loginForm () {
        return {
          email: {
            label: 'E-mail',
            fieldType: 'email',
            type: String,
            default: this.info.email,
            validation: (value) => {
              var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return re.test(value)
            },
            required: true,
            danger: 'Проверьте правильность введенного адреса'
          },
          password: {
            label: 'Пароль',
            fieldType: 'password',
            type: String,
            default: '',
            validation: (value) => {
              return value.length > 5
            },
            required: true,
            danger: 'Поле не должно содержать менее 6 символов'
          }
        }
      },
      registerFormEntity () {
        return {
          name: {
            label: 'Имя',
            fieldType: 'text',
            type: String,
            default: this.name,
            validation: (value) => {
              return value.length > 1
            },
            required: true,
            danger: 'Поле не должно содержать менее 2 символов',
            class: ['col-12', 'col-sm-6', 'col-md-12', 'col-lg-6']
          },
          lastName: {
            label: 'Фамилия',
            fieldType: 'text',
            type: String,
            default: this.lastName,
            validation: (value) => {
              return value.length > 1
            },
            required: true,
            danger: 'Поле не должно содержать менее 2 символов',
            class: ['col-12', 'col-sm-6', 'col-md-12', 'col-lg-6']
          },
          phone: {
            label: 'Мобильный телефон',
            fieldType: 'phone',
            type: String,
            default: this.info.phone,
            validation: (value) => {
              return value.substring(0, 17).replace(/[^0-9\.]/g, '').length === 11
            },
            required: true,
            danger: 'Проверьте правильность введенного номера телефона',
            class: ['col-12', 'col-sm-6', 'col-md-12', 'col-lg-6']
          },
          email: {
            label: 'E-mail',
            fieldType: 'email',
            type: String,
            default: this.info.email,
            validation: (value) => {
              var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return re.test(value)
            },
            required: true,
            danger: 'Проверьте правильность введенного адреса',
            class: ['col-12', 'col-sm-6', 'col-md-12', 'col-lg-6']
          },
          inn: {
            label: 'ИНН',
            fieldType: 'text',
            type: String,
            default: '',
            validation: (value) => {
              return this.isInn(value)
            },
            required: true,
            danger: 'Проверьте правильность введенного номера ИНН'
          },
          password: {
            label: 'Пароль',
            fieldType: 'password',
            type: String,
            default: '',
            validation: (value) => {
              return value.length > 5
            },
            required: true,
            danger: 'Поле не должно содержать менее 6 символов'
          },
          passwordRepeat: {
            label: 'Повторите пароль',
            fieldType: 'password',
            type: String,
            default: '',
            validation: (value) => {
              return value.length > 5
            },
            required: true,
            danger: 'Поле не должно содержать менее 6 символов'
          }
        }
      },
      registerFormIndividual () {
        return {
          name: {
            label: 'Имя',
            fieldType: 'text',
            type: String,
            default: this.name,
            validation: (value) => {
              return value.length > 1
            },
            required: true,
            danger: 'Поле не должно содержать менее 2 символов',
            class: ['col-12', 'col-sm-6', 'col-md-12', 'col-lg-6']
          },
          lastName: {
            label: 'Фамилия',
            fieldType: 'text',
            type: String,
            default: this.lastName,
            validation: (value) => {
              return value.length > 1
            },
            required: true,
            danger: 'Поле не должно содержать менее 2 символов',
            class: ['col-12', 'col-sm-6', 'col-md-12', 'col-lg-6']
          },
          phone: {
            label: 'Мобильный телефон',
            fieldType: 'phone',
            type: String,
            default: this.info.phone,
            validation: (value) => {
              return value.substring(0, 17).replace(/[^0-9\.]/g, '').length === 11
            },
            required: true,
            danger: 'Проверьте правильность введенного номера телефона',
            class: ['col-12', 'col-sm-6', 'col-md-12', 'col-lg-6']
          },
          email: {
            label: 'E-mail',
            fieldType: 'email',
            type: String,
            default: this.info.email,
            validation: (value) => {
              var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return re.test(value)
            },
            required: true,
            danger: 'Проверьте правильность введенного адреса',
            class: ['col-12', 'col-sm-6', 'col-md-12', 'col-lg-6']
          },
          password: {
            label: 'Пароль',
            fieldType: 'password',
            type: String,
            default: '',
            validation: (value) => {
              return value.length > 5
            },
            required: true,
            danger: 'Поле не должно содержать менее 6 символов'
          },
          passwordRepeat: {
            label: 'Повторите пароль',
            fieldType: 'password',
            type: String,
            default: '',
            validation: (value) => {
              return value.length > 5
            },
            required: true,
            danger: 'Поле не должно содержать менее 6 символов'
          }
        }
      }
    },
    watch: {
      shown () {
        const body = document.querySelector('body')
        const toasted = document.getElementById('toasted-container')
        const modal = this.$el

        const eventListener = (event) => {
          let isParent = false
          let node = event.target.parentNode
          while (node != null) {
            if (node === modal) {
              isParent = true
            }
            node = node.parentNode
          }
          if (!isParent) {
            for (var i = 0; i < event.target.attributes.length; i++) {
              const attr = event.target.attributes[i]
              if (attr.nodeName.startsWith('data-v-')) {
                var attrName = attr.nodeName
              }
              for (var j = modal.attributes.length - 1; j >= 0; j--) {
                if (
                  modal.attributes[j].nodeName === attrName &&
                  modal !== event.target
                ) {
                  isParent = true
                }
              }
            }
          }
          if (!isParent) {
            this.$emit('close')
          }
        }

        if (this.shown) {
          const backdrop = document.createElement('div')

          backdrop.classList.add('modal-backdrop')
          backdrop.classList.add('fade')
          backdrop.classList.add('show')

          const padding = this.getScrollbarWidth()
          body.classList.add('modal-open')
          body.appendChild(backdrop)

          if (padding > 0) {
            modal.style.paddingRight = padding + 'px'
            body.style.paddingRight = padding + 'px'
            if (toasted) {
              toasted.style.paddingRight = padding / 2 + 'px'
            }
          }

          modal.style.display = 'block'
          // setTimeout(() => {
          //    modal.classList.add('show')
          // }, 0)
          modal.addEventListener('click', eventListener.bind(this))
        } else {
          const backdrop = document.querySelector('.modal-backdrop')
          // modal.classList.remove('show')
          modal.addEventListener('click', eventListener.bind(this))
          setTimeout(() => {
            modal.style.display = 'none'
          }, 300)
          modal.style.paddingRight = '0'
          body.style.paddingRight = '0'
          if (toasted) {
            toasted.style.paddingRight = 0
          }
          body.classList.remove('modal-open')

          backdrop.classList.remove('show')
          setTimeout(() => {
            body.removeChild(backdrop)
          }, 300)
        }
        this.$emit('change')
      }
    },
    methods: {
      ...mapActions('user', ['login', 'register', 'updateBasketFull', 'restorePassword']),
      handleLoginFormSubmit (formValues) {
        const params = {
          email: formValues.email,
          password: formValues.password
        }
        this.loading = true
        this.login(params).then(() => {
          this.$emit('close')
          this.updateBasketFull()
          this.$toasted.success(`Добро пожаловать ${this.lastName} ${this.name}.`, { position: 'top-right' })
        }).catch((data) => {
          this.$toasted.error(`${data.error.message}.`, { position: 'top-right' })
        }).finally(() => {
          this.loading = false
        })
      },
      handleRegisterFormSubmit (formValues) {
        if (formValues.password !== formValues.passwordRepeat) {
          this.$toasted.error(`Не совпадают пароли.`, { position: 'top-right' })
        } else {
          const params = {
            'captcha': formValues.captcha,
            'email': formValues.email,
            'password': formValues.password,
            'name': formValues.name.trim(),
            'last_name': formValues.lastName.trim(),
            'phone': formValues.phone,
            'inn': formValues.inn ? formValues.inn : '0000000000'
          }
          this.loading = true
          this.register(params).then(() => {
            this.$toasted.success(`Спасибо за регистрацию.`, { position: 'top-right' })
            this.$emit('close')
            this.switchForms = 'login'
            if (window.yaCounter30403442) {
              window.yaCounter30403442.reachGoal('registracia')
            }
          }).catch((data) => {
            this.$toasted.error(`${data.error.message}.`, { position: 'top-right' })
          }).finally(() => {
            this.loading = false
          })
        }
      },
      handlePasswordRestoreFormSubmit (formValues) {
        const params = {
          captcha: formValues.captcha,
          email: formValues.email
        }
        this.restorePassword(params).then(data => {
          this.$toasted.success(`Ссылка отправлена.`, { position: 'top-right' })
        }).catch(data => {
          this.$toasted.error(`${data.error.message}.`, { position: 'top-right' })
        })
      },
      getScrollbarWidth () {
        const tmpWidth = document.body.clientWidth
        const tmpOverflow = document.body.style.overflow

        const outer = document.createElement('div')
        const inner = document.createElement('p')

        const innerCssText = `
                height:  200px;
                width:   100%;
                margin:  0;
                padding: 0;
            `
        const outerCssText = `
                position:   absolute;
                left:       0;
                top:        0;
                visibility: hidden;
                width:      200px;
                height:     150px;
                overflow:   hidden;
                margin:     0;
                padding:    0;
            `

        inner.style.cssText = innerCssText
        outer.style.cssText = outerCssText

        outer.appendChild(inner)
        document.body.appendChild(outer)

        let w1 = inner.offsetWidth
        outer.style.overflow = 'scroll'
        let w2 = inner.offsetWidth
        if (w1 === w2) w2 = outer.clientWidth

        document.body.removeChild(outer)
        document.body.style.overflow = 'hidden'

        const width = (tmpWidth === document.body.clientWidth) ? 0 : w1 - w2

        document.body.style.overflow = tmpOverflow

        return width
      }
    }
  }
</script>

<template lang="html">
    <div class="c-login v-component modal fade" :class="{show: shown}">
        <div class="modal-dialog">
            <div class="modal-content" :class="bem('container')" v-if="switchForms === 'login'">
                <div class="modal-header" :class="bem('header')">
                    <p class="modal-title">Вход</p>
                    <button class="close" type="button" @click="$emit('close')">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body" :class="bem('body')">
                    <form-component :form="loginForm"
                                    :isLoading="loading"
                                    :captcha="false"
                                    :showProgress="false"
                                    btnText="Войти"
                                    btnTextLoading="Обработка"
                                    @submit="handleLoginFormSubmit($event)">
                    </form-component>
                    <a class="switch ml-1" href="#" @click.prevent="switchForms = 'register'">
                        Зарегистрироваться
                    </a>
                    <br/>
                    <a class="switch ml-1" href="#" @click.prevent="switchForms = 'passwordRestore'">
                        Забыли пароль?
                    </a>
                </div>
            </div>
            <div class="modal-content" :class="bem('container')" v-if="switchForms === 'register'">
                <div class="modal-header" :class="bem('header')">
                    <p class="modal-title">Регистрация</p>
                    <button class="close" type="button" @click="$emit('close')">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body" :class="bem('body')">
                    <div class="btn-group mb-3 mt-1">
                        <div class="btn btn-outline-primary" :class="{ active: isEntity }" @click="isEntity = true">
                            Юридическое лицо
                        </div>
                        <div class="btn btn-outline-primary" :class="{ active: !isEntity }" @click="isEntity = false">
                            Физическое лицо
                        </div>
                    </div>
                    <form-component :form="isEntity ? registerFormEntity : registerFormIndividual"
                                    :isLoading="loading"
                                    :showProgress="false"
                                    btnText="Зарегистрироваться"
                                    btnTextLoading="Регистрация"
                                    @submit="handleRegisterFormSubmit($event)">
                    </form-component>
                    <a class="switch ml-1" href="#" @click.prevent="switchForms = 'login'">← назад</a>
                </div>
            </div>
            <div class="modal-content" :class="bem('container')" v-if="switchForms === 'passwordRestore'">
                <div class="modal-header" :class="bem('header')">
                    <p class="modal-title">Восстановление пароля</p>
                    <button class="close" type="button" @click="$emit('close')">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body" :class="bem('body')">
                    <form-component :form="passwordRestoreForm"
                                    :isLoading="loading"
                                    :showProgress="false"
                                    btnText="Восстановить пароль"
                                    btnTextLoading="Восстановление пароля"
                                    @submit="handlePasswordRestoreFormSubmit($event)">
                    </form-component>
                    <a class="switch ml-1" href="#" @click.prevent="switchForms = 'login'">← назад</a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~assets/scss/mixins';
    // $debug: true;
    @include component('login') {
        @include element('container') {
            border: 1px solid $brand-primary-light;
            background: $sct-background-image;
        }
        @include element('header') {
            background-color: $brand-primary-lighter;
            .modal-title {
                font-size: 1.7rem;
            }
            .close {
                font-size: 2rem;
                margin-top: rem(-3px);
            }
        }
        @include element('body') {
            background-color: $brand-primary-lightest;
            .message {
                background-color: $brand-primary-lighter;
                border: 1px solid $brand-primary-light;
                border-radius: $border-radius;
                padding: $spacer / 2;
                padding-left: 0;
                width: 100%;

                &:empty {
                    display: none;
                }
            }
            .label {
                color: $gray-light;
            }
            .switch {
                display: inline-block;
                position: relative;
                top: -$spacer * 2;
            }
        }
        @include element('footer') {
            background-color: $brand-primary-lighter;
        }
        &.v-component {
        }
    }
</style>
