export const state = () => ({
  menu: []
})

export const getters = {
  menu (state) {
    return state.menu
  }
}

export const actions = {
  async updateState ({ commit }) {
    const data = await this.$axios.$get('/header/menu.php')
    commit('UPDATE_STATE', data)
  }
}

export const mutations = {
  UPDATE_STATE (state, data) {
    state.menu = data
  }
}
