<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'BasketComponent',
    computed: {
      ...mapGetters('user', ['basket', 'basketPrice', 'basketWeight']),
      quantityFull () {
        let result = 0
        for (let key in this.basket.quantity) {
          result += parseInt(this.basket.quantity[key])
        }

        return result
      },
      quantityTypes () {
        return Object.keys(this.basket.quantity).length
      }
    },
    methods: {
      formatPrice (n) {
        let price = n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ')
        return price
      },
      pluralize (n, f) {
        n %= 100
        if (n > 10 && n < 20) return f[2]
        n %= 10
        return f[n > 1 && n < 5 ? 1 : n === 1 ? 0 : 2]
      }
    }
  }
</script>

<template lang="html">
    <nuxt-link class="c-basket v-component" exact="exact" to="/order/">
        <div class="row" :class="bem('container')">
            <div class="col-12">
                <p class="sct-title">корзина: {{ formatPrice(basketPrice) }} р.</p>
                <span class="prop">{{ quantityTypes }} {{ pluralize(quantityTypes, ['вид', 'вида', 'видов']) }}</span>
                <span class="prop">{{ quantityFull }} ед.</span>
                <span class="prop">{{ basketWeight / 1000 }} кг.</span>
            </div>
        </div>
    </nuxt-link>
</template>

<style lang="scss" scoped>
    @import '~assets/scss/mixins';

    // $debug: true;

    @include component('basket') {
        @include element('container') {
            @include clearfix;
            @include media-breakpoint-down('md') {
                background-color: transparent;
                border-style: none;
                margin-top: $spacer / 5;
                padding: 0;
                padding-right: $spacer;
                text-align: right;
            }
            @include media-breakpoint-only('xs') {
                margin: $spacer 0;
                padding-left: $spacer;
                text-align: center;
            }
            background-color: rgba($white, 0.9);
            border-radius: $border-radius;
            border: 1px solid darken($gray-lighter, 5%);
            padding-bottom: $spacer / 2;
            padding-top: $spacer / 2;
            .sct-title {
                float: left;
                font-size: rem(13px);
                font-weight: bold;
                margin-bottom: 0;
                text-transform: uppercase;
                white-space: nowrap;
                width: 100%;
            }
            .prop {
                @include media-breakpoint-down('md') {
                    font-size: rem(13px);
                    float: none;
                }
                color: $gray-light;
                float: left;

                &::after {
                    content: '|';
                    margin: 1rem $spacer / 3;
                }

                &:last-child {
                    &::after {
                        content: '';
                        margin: 0;
                    }
                }
            }
        }
        &.v-component {
            @include media-breakpoint-only('lg') {
                margin-top: -$spacer / 2;
            }
            @include media-breakpoint-down('md') {
                margin-top: 0;
                padding: 0 2px 0 0
            }
            @include hfas('active') {
                text-decoration: none;
            }
            margin-top: $spacer / 4;
            padding-bottom: $spacer / 2;
            padding-right: $spacer * 2;
            padding-top: $spacer / 2;
        }
    }
</style>
