<script>
  export default {
    name: 'FooterContainer'
  }
</script>

<template lang="html">
    <div class="b-footer v-container">
        <div class="container-fluid">
            <div class="row pt-4 pb-3" :class="bem('container')">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-5 col-md-4 col-lg-3 text-center text-sm-left mb-2" :class="bem('logo')">
                            <nuxt-link exact="exact" to="/">
                                <img class="image" src="~assets/images/logo-new.png"/>
                            </nuxt-link>
                        </div>
                        <div class="col-sm-7 col-md-3 col-lg-3 text-center" :class="bem('phones')">
                            <p class="text-center text-sm-right phone">+7 (499) 404-05-66</p>
                            <p class="text-center text-sm-right phone">+7 (473) 220-42-20</p>
                        </div>
                        <div class="col-12 col-md-5 col-lg-6">
                            <ul class="text-center text-sm-left text-md-right" :class="bem('menu')">
                                <li class="text-center text-sm-right item">
                                    <nuxt-link class="link" to="/static/about/" exact="exact">О компании</nuxt-link>
                                </li>
                                <li class="text-center text-sm-right item">
                                    <nuxt-link class="link" to="/contact/" exact="exact">Контакты</nuxt-link>
                                </li>
                                <li class="text-center text-sm-right item">
                                    <nuxt-link class="link" to="/static/delivery/" exact="exact">
                                        Доставка и оплата
                                    </nuxt-link>
                                </li>
                                <li class="text-center text-sm-right item">
                                    <nuxt-link class="link" to="/partners/" exact="exact">Стать клиентом</nuxt-link>
                                </li>
                                <li class="text-center text-sm-right item">
                                    <nuxt-link class="link" to="/career/" exact="exact">Карьера</nuxt-link>
                                </li>
                                <li class="text-center text-sm-right item">
                                    <nuxt-link class="link" to="/kits/" exact="exact">Акции</nuxt-link>
                                </li>
                                <li class="text-center text-sm-right item">
                                    <nuxt-link class="link" to="/feedback/" exact="exact">Отзывы</nuxt-link>
                                </li>
                                <li class="text-center text-sm-right item">
                                    <a class="link"
                                       target="_blank"
                                       href="https://www.youtube.com/channel/UCkdmD_rQ40Hf-FFMeTupj7Q">
                                        Инструкции
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~assets/scss/variables';
    @import '~assets/scss/mixins';
    // $debug: true;
    @include block('footer') {
        @include element('container') {
        }
        @include element('logo') {
            .image {
                width: rem(220px);
                max-width: 100%;
            }
        }
        @include element('phones') {
            padding-top: $spacer / 6;
            .phone {
                @include media-breakpoint-down('md') {
                    font-size: .9rem;
                }
                @include media-breakpoint-down('sm') {
                    text-align: right;
                }
                font-weight: 700;
                margin-bottom: $spacer / 2;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        @include element('menu') {
            @include clearfix;
            @include media-breakpoint-down('sm') {
                margin-top: $spacer * 1.5;
            }
            list-style: none;
            margin-bottom: 0;
            padding: 0;
            .item {
                @include media-breakpoint-down('sm') {
                    margin-left: 0;
                    margin-right: $spacer;
                }
                @include media-breakpoint-down('xs') {
                    margin-left: $spacer / 2;
                    margin-right: $spacer / 2;
                }
                display: inline-block;
                line-height: 1.8;
                margin-left: $spacer;
                margin-right: 0;

                &:last-child {
                    margin-right: 0;
                }
            }
            .link {
                @include hover-focus-active {
                    color: $brand-primary;
                }
                color: $gray-light;
                text-decoration: underline;
            }
        }
        &.v-container {
            background: linear-gradient(180deg, $sct-background-color-light 0%, $sct-background-color-light-transparent 100%);
            border-top: 1px solid darken($gray-lighter, 5%);
        }
    }
</style>
