export const state = () => ({
  sales: []
})

export const mutations = {
  UPDATE_STATE (state, data) {
    state.sales = data
  }
}

export const getters = {
  sales (state) {
    return state.sales
  }
}
