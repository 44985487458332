import { ApiError } from '~/helpers/custom-errors'

export default function ({ $axios, app }) {
  $axios.onResponse(response => {
    if (response.data && response.data.success === false) {
      app.$sentry.withScope(scope => {
        scope.setExtra('data', {
          url: response.config.url,
          code: response.status,
          params: response.config.params,
          response: response.data
        })
        app.$sentry.captureException(new ApiError(
          response.data.error && response.data.error.message ? response.data.error.message : 'Unknown error'
        ))
      })
    }
  })

  const normalStatusList = [422, 301]
  $axios.onError(error => {
    // TODO: disable logging for some requests (maybe catalog/excel)
    // if (error.response.config.headers['X-Request-No-Logging']) {
    //   return
    // }

    const response = error.response
    if (response) {
      const data = response.data
      if (!normalStatusList.includes(response.status)) {
        app.$sentry.withScope(scope => {
          scope.setExtra('data', {
            url: response.config.url,
            code: response.status,
            params: response.config.params,
            response: data || {}
          })
          app.$sentry.captureException(new ApiError(
            data && data.error && data.error.message ? data.error.message : 'Unknown error'
          ))
        })
      }
    } else {
      app.$sentry.captureException(new ApiError('Axios error without response'))
    }
  })
}
