<template lang="html">
    <div class="p-error v-page">
        <div class="container-fluid">
            <div class="row p-error__container">
                <div class="container">
                    <div class="row pb-5 pt-4 pt-xl-5">
                        <div class="col-12 text-center">
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="h2 text-primary">{{ error.statusCode }}</h2>
                                    <h3 class="h3 mb-4">
                                        {{ error.statusCode === 404? 'Страница не найдена.' : error.message }}
                                    </h3>
                                    <p class="text text-muted mb-0">На главную страницу</p>
                                    <nuxt-link class="link" to="/">https://sct.ru →</nuxt-link>
                                </div>
                            </div>
                            <div class="row retail-rocket-row">
                                <div class="col-12">
                                    <div data-retailrocket-markup-block="5cbf032797a52522d87613e7"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: ['error'],
    layout: 'default'
  }
</script>

<style lang="scss">
    @import '~assets/scss/variables';
    @import '~assets/scss/mixins';

    @include page('error') {
        .retail-rocket-row > div {
            margin: 0 auto;
        }
    }
</style>
