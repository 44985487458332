export const state = () => ({
  items: []
})

export const mutations = {
  UPDATE_STATE (state, data) {
    state.items = data.items
  }
}

export const getters = {
  items (state) {
    return state.items
  }
}
