export const state = () => ({
  items: []
})

export const getters = {
  items (state) {
    return state.items
  }
}

export const actions = {
  updateState (store) {
    this.$axios.$get('/pdf/').then(data => {
      store.commit('UPDATE_STATE', data)
    })
  }
}

export const mutations = {
  UPDATE_STATE (state, data) {
    state.items = data
  }
}
