export const state = () => ({
  reviews: []
})

export const getters = {
  reviews (state) {
    return state.reviews
  }
}

export const actions = {
  updateState (store) {
    return new Promise((resolve, reject) => {
      this.$axios.$get('/static/feedback/')
        .then(data => {
          store.commit('UPDATE_STATE', data)
          resolve(data)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}

export const mutations = {
  UPDATE_STATE (state, data) {
    state.reviews = data
  }
}
