export const state = () => ({
  page: '',
  content: '',
  title: ''
})

export const mutations = {
  UPDATE_STATE (state, data) {
    state.page = data.page
    state.content = data.content
    state.title = data.title
  }
}

export const getters = {
  page (state) {
    return state.page
  },
  content (state) {
    return state.content
  },
  title (state) {
    return state.title
  }
}
